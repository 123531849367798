import { GatsbyLinkProps, Link } from "gatsby";
import * as React from "react";
import { FC, ReactNode } from "react";

import ExploreTile from "../../../../components/ExploreTile";
import * as Styled from "./styles/ExploreTilesDashboard.styles";

export interface tileType {
  title: string;
  isAvailable: boolean;
  url?: string;
  linkText?: string;
  linkIcon?: "forward" | "chevron-right";
  order: number;
  image: {
    file: {
      url: string;
    };
  };
  darkImage: {
    file: {
      url: string;
    };
  };
}

interface ExploreTilesDashboard {
  children?: ReactNode;
  isDark?: boolean;
  locale: string;
  tiles?: Array<tileType>;
}

export const ExploreTileMapper = (isDark: boolean, locale: string) => {
  const ExploreTileMapped = ({
    title,
    linkIcon,
    linkText,
    isAvailable,
    darkImage,
    url,
    image,
    order
  }: tileType) => {
    const tileId = title.replace(/\s/g, "");
    return (
      <Styled.DashboardItem key={title}>
        <ExploreTile<Partial<GatsbyLinkProps<unknown>>>
          as={(url && url.includes("www")) || !isAvailable ? undefined : Link}
          headingText={title}
          id={tileId}
          iconText={linkText}
          icon={linkIcon || "chevron-right"}
          image={isDark ? darkImage.file?.url : image.file?.url}
          imageAltText=""
          to={url && !url.includes("www") ? `/${locale}${url}` : undefined}
          href={url}
          target={url && url.includes("www") ? "_blank" : undefined}
          hasPill={!isAvailable}
          pillText="Coming Soon..."
          notifications={order === 1 ? 1 : 0}
        />
      </Styled.DashboardItem>
    );
  };
  return ExploreTileMapped;
};

const ExploreTilesDashboard: FC<ExploreTilesDashboard> = ({
  children,
  isDark = false,
  locale,
  tiles
}: ExploreTilesDashboard) => {
  return (
    <>
      {children}
      {tiles?.map(ExploreTileMapper(isDark, locale))}
    </>
  );
};

export default ExploreTilesDashboard;
