import styled, { css } from "styled-components";

export const Image = styled.div`
  ${(props) => css`
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
    picture {
      align-self: center;
      display: block;
      height: 80px;
      width: 80px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    h4 {
      align-self: center;
    }
    @media (max-width: ${props.theme.breakpoints.md}px) {
      flex-direction: column;
      gap: 0;
      margin: 0;
    }
  `}
`;
