import React from "react";

import { Heading } from "@source-web/heading";
import { Image } from "@source-web/image";

import { getIconForTheme } from "../../lib";
import { ContentfulCarouselItem } from "../../pageTemplates/dashboard/queries/queries.types";
import * as Styled from "./styles";

interface PartnerImageProps {
  isDark: boolean;
  heading: ContentfulCarouselItem["dashboardWidgetCybershopHeading"];
  mainIconLight: ContentfulCarouselItem["mainIconLight"];
  mainIcon: ContentfulCarouselItem["mainIcon"];
  alt: string;
}

export const PartnerImage: React.FC<PartnerImageProps> = ({
  isDark,
  heading,
  mainIconLight,
  mainIcon,
  alt
}) => (
  <Styled.Image>
    <Image
      dataSelectorPrefix="carousel-image"
      alt={`${alt} ${isDark ? "dark" : "light"}`}
      sm={{
        src: getIconForTheme(isDark, mainIcon, mainIconLight)
      }}
      md={{
        src: getIconForTheme(isDark, mainIcon, mainIconLight)
      }}
      lg={{
        src: getIconForTheme(isDark, mainIcon, mainIconLight)
      }}
    />
    <Heading inverse={isDark} level={4} weight={3} size={2} justify="center">
      {heading}
    </Heading>
  </Styled.Image>
);
