import React from "react";

import { Divider } from "@source-web/divider";
import { Paragraph } from "@source-web/paragraph";
import { Spacing } from "@source-web/spacing";

import { PartnerImage } from "../../../../../components/PartnerImage";
import { useAuth } from "../../../../../context/auth/useAuth";
import { ContentfulDashboardWidgetStepUnit } from "../../../queries/queries.types";
import { DashboardWidgetHeader } from "../atoms";
import * as DashboardWidget from "../styles/DashboardWidget.styles";

export const SSCWelcome = ({
  fields,
  isDark
}: {
  isDark: boolean;
  fields: ContentfulDashboardWidgetStepUnit | undefined;
}) => {
  const user = useAuth().authState.user;

  if (!fields || !user?.firstName) return null;

  const {
    heading,
    subHeading,
    description: { description }
  } = fields;

  const tile = fields.explainerTilesCarousel?.find(
    (tile) => tile.serviceId === 10
  );

  return (
    <DashboardWidget.ScoreCardWrapper>
      <DashboardWidgetHeader
        activeStep={1}
        // Remove name according to CYB-18463
        heading={heading.replace("{username}", "")}
        subHeading={subHeading}
      />

      <Divider appearance="secondary" />
      <DashboardWidget.StepBody shouldMargin id="securityScoreCard">
        <Spacing
          spacingLevel={{
            top: 8,
            bottom: 10,
            right: 10
          }}
        >
          <Paragraph justify="left">{description}</Paragraph>
        </Spacing>

        {tile && (
          <PartnerImage
            isDark={isDark}
            heading={tile.dashboardWidgetCybershopHeading}
            mainIcon={tile.mainIcon}
            mainIconLight={tile.mainIconLight}
            alt={"ssc"}
          />
        )}
      </DashboardWidget.StepBody>
      <Divider appearance="secondary" />
    </DashboardWidget.ScoreCardWrapper>
  );
};
