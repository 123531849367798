import { paramCase } from "param-case";
import React, { useEffect, useMemo } from "react";

import { Loader } from "../../../../../components";
import { AdviseNotification } from "../../../../../components/AdviseNotification";
import { ProgressBar } from "../../../../../components/ProgressBar";
import { lookoutFactory } from "../../../../../context/auth/helpers";
import {
  errorMessages,
  is60PercentOf,
  useEventSource
} from "../../../../../lib";
import { ContentfulPackageTile } from "../../../../profile/types";
import { InsightsBase } from "../../../../servicesPage/types";
import { ServiceMapperProps } from "../components/MyServices";
import * as DashboardWidgetStyles from "../styles/DashboardWidget.styles";

export function LookoutDashboardWidget({
  lookoutFields,
  heading,
  setShouldShowWarningIcon
}: {
  heading: string;
  lookoutFields: ContentfulPackageTile;
  setShouldShowWarningIcon: ServiceMapperProps["setShouldShowWarningIcon"];
}) {
  const { isLoading, data, isError } = useEventSource<InsightsBase>(
    `lookout/insights/`,
    {
      enabled: true,
      dataParsingFunction: lookoutFactory,
      isSingleEntity: true
    }
  );
  const lookoutData = data as InsightsBase;

  useEffect(() => {
    if (lookoutData) {
      setShouldShowWarningIcon({
        [paramCase(heading)]:
          lookoutData?.licensesPurchased !== lookoutData?.licensesUsed
      });
    }
  }, [lookoutData, heading, setShouldShowWarningIcon]);

  const renderStates = useMemo(() => {
    if (isError || (!lookoutData && !isLoading)) {
      return (
        <AdviseNotification state="error" text={errorMessages.unexpected} />
      );
    }
    if (isLoading) {
      return <Loader text={{ text: "" }} isSmall height="auto" />;
    }

    if (!lookoutData) return null;

    const { licensesPurchased, licensesUsed } = lookoutData;

    const usedDevicesPercentage =
      licensesUsed && licensesPurchased
        ? Math.round(
            Math.ceil(licensesUsed * 100) / Math.ceil(licensesPurchased)
          )
        : 0;
    const isSixtyPercent = is60PercentOf(licensesUsed, licensesPurchased);
    const appearance = isSixtyPercent ? "secondary" : "primary";

    return (
      <DashboardWidgetStyles.ProductWidgetWrapper>
        <ProgressBar
          shouldGap
          appearance={appearance}
          value={usedDevicesPercentage}
          inverse
          topText={{
            left: `${lookoutFields.widgetSubText
              .replace("{value1}", licensesUsed.toString())
              .replace("{value2}", licensesPurchased.toString())}`
          }}
          srLabel={`${licensesUsed} devices used from ${licensesPurchased}`}
        />
      </DashboardWidgetStyles.ProductWidgetWrapper>
    );
  }, [isError, lookoutData, isLoading, lookoutFields.widgetSubText]);

  return <> {renderStates}</>;
}
