import { navigate } from "gatsby";
import { paramCase } from "param-case";
import React, { FC, Fragment, useEffect, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters
} from "react-query";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Span } from "@source-web/span";

import { PrimaryButton } from "../../../../components/PrimaryButton";
import SecurityScore from "../../../../components/SecurityScore";
import { useCampaignFormContext } from "../../../../context/forms/useForms";
import {
  ISecurityScorecardRootObject,
  getGradeBasedOnScore,
  haveSScAccount,
  trackSscClick,
  triggerMedalliaView
} from "../../../../lib/";
import { ContentfulScoreConfiguration } from "../../../servicesPage/types";
import {
  ContentfulSecurityScoreAvailability,
  ContentfulSecurityScoreConfirmation
} from "../../queries/queries.types";
import { SecurityScoreModal } from "./SecurityScoreModal/SecurityScoreModal";
import * as Styled from "./styles/SecurityScoreWidget.styles";

export interface ScoreWidgetProps {
  locale: string;
  formattedLocale: string;
  title: string;
  subTitle: string;
  buttonText: string;
  providedBySscMessage: string;
  emptyScoreMessage: string;
  filledScoreButtonText: string;
  calculatingText: string;
  availabilityFields: ContentfulSecurityScoreAvailability["contentfulSecurityScoreAvailability"];
  confirmationContentfulFields: ContentfulSecurityScoreConfirmation["contentfulSecurityScoreConfirmation"];
  isLoading: boolean;
  data: ISecurityScorecardRootObject | undefined;
  isFetched: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ISecurityScorecardRootObject, unknown>>;
  setCanBeFetched: React.Dispatch<boolean>;
  securityScoreCardConfigurationData: ContentfulScoreConfiguration;
}

export interface getScoreAssessment {
  industry: string;
  partyRole: {
    name: string;
    role: string;
  };
  riskAssessmentResult: {
    overallScore: number;
    score: {
      riskName: string;
      score: number;
    }[];
  };
}

const SecurityScoreWidget: FC<ScoreWidgetProps> = ({
  title,
  buttonText,
  filledScoreButtonText,
  locale,
  confirmationContentfulFields,
  securityScoreCardConfigurationData,
  formattedLocale,
  availabilityFields,
  emptyScoreMessage,
  calculatingText,
  isLoading,
  data,
  isFetched,
  refetch,
  setCanBeFetched,
  ...rest
}: ScoreWidgetProps) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isCyberScoreAvailable, toggleCyberScoreAvailability] = useState(false);

  const theme = useTheme();
  const { setIsModalOpen, hasScore, setHasScore } = useCampaignFormContext();

  const handleGetCyberScoreClick = () => {
    toggleCyberScoreAvailability(true);
    setCanBeFetched(true);
  };

  const openScoreForm = () => {
    setIsFormOpen(true);
    setIsModalOpen(true);
    trackSscClick({ page_locale: locale });
  };

  const closeScoreForm = () => {
    setIsFormOpen(false);
    setIsModalOpen(false);
    triggerMedalliaView();
  };

  useEffect(() => {
    if (isFetched && data?.score && !hasScore) setHasScore(true);
  }, [isFetched, hasScore, setHasScore, data?.score]);

  const handleButtonClick = (score: number | undefined) => {
    if (score) {
      trackSscClick({ page_locale: locale });
      return navigate(
        `/${formattedLocale}/my-services?service=${paramCase(
          "security-scorecard"
        )}`
      );
    }
    if (!score && haveSScAccount) {
      trackSscClick({ page_locale: locale });
      return refetch();
    }

    openScoreForm();
  };

  return (
    <Fragment>
      <SecurityScoreModal
        availabilityFields={availabilityFields}
        confirmationContentfulFields={confirmationContentfulFields}
        onClose={() => closeScoreForm()}
        isScoreFetched={isFetched}
        isOpen={isFormOpen}
        isCyberScoreAvailable={isCyberScoreAvailable}
        handleGetCyberScoreClick={handleGetCyberScoreClick}
        formattedLocale={formattedLocale}
      />

      <Styled.SecurityScoreWrapper id="securityScore">
        <Heading
          noMargin
          weight={1}
          appearance="primary"
          inverse={theme.name !== "WS10"}
          size={3}
        >
          {title}
        </Heading>

        <SecurityScore
          isCalculating={isLoading}
          score={data?.score}
          rating={getGradeBasedOnScore(data?.score)}
          calculatingText={calculatingText}
          emptyScoreMessage={emptyScoreMessage}
          securityScoreCardConfigurationData={
            securityScoreCardConfigurationData
          }
        />

        <Styled.PaddedContainer>
          <PrimaryButton
            loading={isLoading}
            text={data?.score ? filledScoreButtonText : buttonText}
            onClick={() => {
              handleButtonClick(data?.score);
            }}
          />
          <div>
            <Span weight={3}>{rest?.providedBySscMessage}</Span>
          </div>
        </Styled.PaddedContainer>
      </Styled.SecurityScoreWrapper>
    </Fragment>
  );
};

export default SecurityScoreWidget;
