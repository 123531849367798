import * as React from "react";

import { Heading } from "@source-web/heading";
import { Modal } from "@source-web/modal";
import { OverlayCloseSource } from "@source-web/overlay-controller";

import { ContentfulRichText } from "../../../../components/ContentfulRichText";
import { PrimaryButton } from "../../../../components/PrimaryButton";
import ResponsiveVideoPlayer from "../../../../components/ResponsivePlayer";
import { PlayerWrapper } from "../../../support/styles/support.styles";
import { WelcomeTourPopupData } from "../../queries/queries.types";
import { DecisionType } from "../../types";
import * as Styled from "../DashboardBanner/styles/DashboardBanner.styles";
import { ButtonWrapper } from "./styles/WelcomeTourPopup.styles";

export interface WelcomeTourPopupType {
  isOpen?: boolean;
  isDark?: boolean;
  takeDecision: ({ decision }: DecisionType) => void;
  data: WelcomeTourPopupData;
}

const WelcomeTourPopup = ({
  isOpen = false,
  isDark,
  takeDecision,
  data
}: WelcomeTourPopupType) => {
  const { video, videoThumbnail, heading, text, mainButton, secondaryButton } =
    data;

  const handleClose = (source?: OverlayCloseSource) => {
    if (source === OverlayCloseSource.MODAL_CROSS_CLICK) {
      takeDecision({ decision: false });
    }
  };

  return (
    <Modal
      srName="welcome-tour-modal"
      isOpen={isOpen}
      size={2}
      isClosable={true}
      onCloseCb={handleClose}
    >
      <>
        <Heading text={heading} size={2} justify="center" level={1} />
        <PlayerWrapper isModalPlayer>
          <ResponsiveVideoPlayer
            className="react-player"
            appearance="primary"
            height="100%"
            width="100%"
            thumbnail={videoThumbnail.file.url}
            controls
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  autoplay: "autoplay"
                }
              }
            }}
            url={video.file.url}
          />
        </PlayerWrapper>
        <Styled.ContentWrapper>
          <Styled.ParagraphWrapper>
            <Styled.ModalText>
              <ContentfulRichText text={text.raw} />
            </Styled.ModalText>
          </Styled.ParagraphWrapper>
          <ButtonWrapper>
            <PrimaryButton
              text={secondaryButton}
              appearance="secondary"
              onClick={() => takeDecision({ decision: false })}
              inverse={isDark}
            />
            <PrimaryButton
              text={mainButton}
              appearance="primary"
              onClick={() => takeDecision({ decision: true })}
              inverse={isDark}
            />
          </ButtonWrapper>
        </Styled.ContentWrapper>
      </>
    </Modal>
  );
};

export default WelcomeTourPopup;
