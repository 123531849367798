import styled from "styled-components";

import { Theme } from "@source-web/themes";

export const DesktopContainerWrapper = styled.div`
  & div[data-selector="-dashboard-banner-image"] {
    padding: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    min-height: 532px;

    & img {
      height: 532px;
    }

    & div[data-selector="-dashboard-banner-image"] {
      padding: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md - 1}px) {
    display: none;
  }
`;
export const DesktopContainerContent = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 1em;
  }
  padding: 1.25em 0 0 3.25em;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  gap: 1.875em;
  height: 100%;
`;

export const MobileContainerWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    display: none;
  }

  & div[data-selector="-dashboard-banner-image"] {
    padding: 0;
  }
`;
export const MobileContent = styled.div`
  padding: 3.25em 2.25em;
  max-width: 600px;

  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 2.25em;
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

const IconItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 0.575em;
  width: 100px;

  & svg {
    width: 80px;
    height: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    flex-direction: row;
    width: fit-content;
    gap: 1em;
    & svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const DesktopContainer = {
  Container: DesktopContainerWrapper,
  Content: DesktopContainerContent
};

export const MobileContainer = {
  Container: MobileContainerWrapper,
  Content: MobileContent
};

export const Icon = {
  Wrapper: IconWrapper,
  Item: IconItem
};

export const PillWrapper = styled.div`
  padding: 1.25em 0 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  ${(props) => ` 
  @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
    display: flex;
    margin-top: 2.25rem;
    justify-content:center;
  } 
  @media only screen and (min-width: ${props.theme.breakpoints.lg + 143}px) {
    position: relative;
    margin-left: 1.5em;
    top: -75px; 
  }
  `};
`;

export const ContentWrapper = styled.div`
  display: inline-block;
  width: 100%;
  ${(props) => `  
  @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
    margin-top: 1rem;
  } 
  @media only screen and (min-width: ${props.theme.breakpoints.lg + 143}px) {
    margin-top: 1rem;
  }  
  `};
`;

export const ParagraphWrapper = styled.div`
  display: inline;
  text-align: center;
  width: 400px;
`;

export const ModalText = styled.div`
  ${(props: { theme: Theme; hasSpacing?: boolean }) => `  
  ${props.hasSpacing ? `margin-top:${props.theme.spacing.fixed[2]}` : null}
  @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
    margin-bottom: ${props.theme.spacing.fixed[3]}
  }  
  @media only screen and (min-width: ${props.theme.breakpoints.lg + 143}px) {
    margin-bottom: 20px;
  }  
  `};
`;

export const CardWrapper = styled.div`
  ${(props: { theme: Theme; marginLeft?: boolean }) => `
   display: flex;
   flex-direction: column;
   justify-content: space-between;
  min-height: 276px;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  & > a {
    display: flex;
    justify-content: end;
  }
  @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
    ${
      props.marginLeft ? `margin-left:${props.theme.spacing.fixed[5]}px` : null
    };
  }

  @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
    margin-top: ${props.theme.spacing.fixed[3]}px;
  }

  padding: ${props.theme.spacing.fixed[3]}px;
  background-color: ${props.theme.name !== "WS10" ? "#25282b" : "#fff"};
  box-shadow: 0px 0px 21px 0px rgb(0 0 0 / 31%);
  border-radius: 7px;
  @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
    margin-bottom: ${props.theme.spacing.fixed[1]}
  @media only screen and (min-width: ${props.theme.breakpoints.lg + 143}px) {
    margin-bottom: ${props.theme.spacing.fixed[1]}
  }
  `};
`;

export const HeaderWrapper = styled.div`
  ${(props) => `
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
    & > span {
      display: none;
    }
  }
  `};
`;
