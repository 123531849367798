import React, { useMemo } from "react";

import { Loader } from "../../../../../components";
import { AdviseNotification } from "../../../../../components/AdviseNotification";
import { ProgressBar } from "../../../../../components/ProgressBar";
import { errorMessages } from "../../../../../lib";
import {
  Devices,
  VSDMData,
  useVSDMData
} from "../../../../servicesPage/hooks/useVSDMData";
import { ContentfulVSDMDashboardTile } from "../../../queries/queries.types";
import {
  VSDMItemTextSignificantPart,
  VSDMItemTextWrapper,
  VSDMProgressBarWrapper,
  VSDMTileWrapper
} from "../styles/DashboardWidget.styles";

export function VSDMDashboardWidget({
  fields
}: {
  fields: ContentfulVSDMDashboardTile;
}) {
  const {
    data,
    isLoading,
    isError
  }: { data: VSDMData; isLoading: boolean; isError: boolean } = useVSDMData();

  const security: { [key: string]: string } | undefined =
    data?.devices?.security;
  const totalDevices = Number(data?.devices?.total);

  const renderStates = useMemo(() => {
    if (isError || (!data && !isLoading)) {
      return (
        <AdviseNotification state="error" text={errorMessages.unexpected} />
      );
    }
    if (isLoading) {
      return <Loader text={{ text: "" }} isSmall height="auto" />;
    }

    const adaptedFields: Devices["security"] = {
      compromised: fields.compromisedPieText,
      noPassCode: fields.noPasscodePieText,
      notEncrypted: fields.notEncryptedPieText
    };

    return (
      <VSDMTileWrapper>
        {security &&
          Object.keys(security).map((objKey) => {
            const perc = Math.round(
              (Number(security[objKey]) * 100) / totalDevices
            );
            return (
              <div key={objKey}>
                <VSDMItemTextWrapper>
                  <span>{`${
                    adaptedFields[objKey as keyof typeof adaptedFields]
                  }: `}</span>
                  <VSDMItemTextSignificantPart>
                    {`${security[objKey]} ${fields.devicesText} (${perc}%)`}
                  </VSDMItemTextSignificantPart>
                </VSDMItemTextWrapper>

                <VSDMProgressBarWrapper>
                  <ProgressBar
                    value={perc}
                    appearance="primary"
                    srLabel="Progress bar"
                  />
                </VSDMProgressBarWrapper>
              </div>
            );
          })}
      </VSDMTileWrapper>
    );
  }, [isError, data, isLoading, fields, security, totalDevices]);

  return <> {renderStates}</>;
}
