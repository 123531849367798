import { navigate } from "gatsby";
import { paramCase } from "param-case";
import React, { useState } from "react";

import {
  CollapsibleContainer,
  CollapsibleContainerBody,
  CollapsibleContainerHeader
} from "@source-web/collapsible-container";
import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";

import { Loader } from "../../../../../components";
import { AdviseNotification } from "../../../../../components/AdviseNotification";
import { PrimaryButton } from "../../../../../components/PrimaryButton";
import { useAuth } from "../../../../../context/auth/useAuth";
import { dashboardCTAsButtonClick } from "../../../../../lib";
import { ContentfulPackageTile } from "../../../../profile/types";
import {
  ContentfulCybSafeDashboardTiles,
  ContentfulDashboardWidgetStepUnit,
  ContentfulServiceTileWidget,
  ContentfulVSDMDashboardTile,
  ServiceTileNode
} from "../../../queries/queries.types";
import { DashboardWidgetProps } from "../DashboardWidget";
import { DashboardWidgetHeader } from "../atoms";
import { ProductWidget } from "../productWidgets";
import * as DashboardWidget from "../styles/DashboardWidget.styles";

interface MyServicesProps {
  fields: ContentfulDashboardWidgetStepUnit | undefined;
  serviceTiles: ContentfulServiceTileWidget | undefined;
  formattedLocale: string;
  isDark: boolean;
  cybSafeTileFields: DashboardWidgetProps["cybSafeTileFields"];
  lookoutFields: ContentfulPackageTile;
  VSDMTileFields: ContentfulVSDMDashboardTile;
  isLoading: boolean;
  isError: boolean;
  availableSubscriptionIds: Array<string>;
}

type ShouldShowIconType = Record<string, boolean>;

export interface ServiceMapperProps {
  products: ServiceTileNode[] | undefined;
  availableSubscriptionIds: string[];
  selectedProduct: ServiceTileNode | null;
  cybSafeTileFields: ContentfulCybSafeDashboardTiles["contentfulCybSafeDashboardTiles"];
  VSDMTileFields: ContentfulVSDMDashboardTile;
  handleProductSelection: (tile: ServiceTileNode) => void;
  lookoutFields: ContentfulPackageTile;
  shouldShowWarningIcon: ShouldShowIconType;
  setShouldShowWarningIcon: React.Dispatch<
    React.SetStateAction<ShouldShowIconType>
  >;
}

const servicesMapper = ({
  products,
  availableSubscriptionIds,
  selectedProduct,
  cybSafeTileFields,
  lookoutFields,
  VSDMTileFields,
  handleProductSelection,
  setShouldShowWarningIcon,
  shouldShowWarningIcon
}: ServiceMapperProps) => {
  return products
    ?.filter(Boolean)
    ?.filter((tile) => {
      return availableSubscriptionIds?.includes(tile.productId);
    })
    .map((tile) => {
      const isProductActive = Boolean(
        selectedProduct?.serviceHlId === tile.serviceHlId
      );
      return (
        <CollapsibleContainer
          key={tile.serviceHlId}
          onToggle={() => {
            handleProductSelection(tile);
          }}
          appearance="secondary"
          isActive={isProductActive}
        >
          <CollapsibleContainerHeader dataSelectorPrefix="collapsable-header">
            <DashboardWidget.HeaderWrapper>
              {tile.dashboardWidgetHeading
                ? tile.dashboardWidgetHeading
                : tile.heading}
              <>
                {shouldShowWarningIcon[paramCase(tile.heading)] ? (
                  <Icon name="warn" group="state" />
                ) : null}
              </>
            </DashboardWidget.HeaderWrapper>
          </CollapsibleContainerHeader>

          <CollapsibleContainerBody>
            <ProductWidget
              heading={tile.heading}
              setShouldShowWarningIcon={setShouldShowWarningIcon}
              cybSafeTileFields={cybSafeTileFields}
              lookoutFields={lookoutFields}
              VSDMTileFields={{
                ...VSDMTileFields,
                devicesText: tile.devicesText || ""
              }}
              selectedProductHighLevelId={tile.serviceHlId}
            />
          </CollapsibleContainerBody>
        </CollapsibleContainer>
      );
    });
};

export const MyServices = ({
  fields,
  serviceTiles,
  formattedLocale,
  cybSafeTileFields,
  lookoutFields,
  VSDMTileFields,
  isDark,
  isLoading,
  isError,
  availableSubscriptionIds
}: MyServicesProps) => {
  const [selectedProduct, setSelectedProduct] =
    useState<ServiceTileNode | null>(null);

  const [shouldShowWarningIcon, setShouldShowWarningIcon] =
    useState<ShouldShowIconType>({});

  const { user } = useAuth().authState;

  if (!user?.firstName || !user.company || !fields) return null;

  const {
    heading,
    subHeading,
    description: { description }
  } = fields;

  const userName = heading.replace("{username}", user?.firstName);
  const companyName = subHeading.replace("{companyName}", user?.company);

  function handleProductSelection(tile: ServiceTileNode) {
    if (tile.serviceHlId === selectedProduct?.serviceHlId && selectedProduct) {
      setSelectedProduct(null);
    }
    if (tile.serviceHlId !== selectedProduct?.serviceHlId) {
      setSelectedProduct(null);
      setSelectedProduct(tile);
    }
  }

  return (
    <DashboardWidget.CustomStepWrapper>
      <DashboardWidget.ServicesListWrapper>
        <DashboardWidgetHeader
          activeStep={3}
          heading={userName}
          subHeading={companyName}
          isDark={isDark}
          icon="user-scan"
        />
        <div>
          <Divider appearance="secondary" />
          <Heading text={description} size={2} />
          <Divider appearance="secondary" />
        </div>
      </DashboardWidget.ServicesListWrapper>

      <DashboardWidget.CollapsibleContainerWrapper>
        {isLoading && <Loader text={{ text: "" }} height="auto" isSmall />}

        {isError && !availableSubscriptionIds && (
          <AdviseNotification text="Error" />
        )}

        {!isLoading &&
          availableSubscriptionIds &&
          servicesMapper({
            products: serviceTiles?.allContentfulServiceTile.nodes,
            availableSubscriptionIds,
            selectedProduct,
            cybSafeTileFields,
            lookoutFields,
            VSDMTileFields,
            handleProductSelection,
            shouldShowWarningIcon,
            setShouldShowWarningIcon
          })}
      </DashboardWidget.CollapsibleContainerWrapper>

      {selectedProduct?.dashboardWidgetButtonText && (
        <DashboardWidget.ProductRedirectButtonContainer height={4}>
          <div>
            <PrimaryButton
              appearance="alt1"
              inverse={isDark}
              text={selectedProduct.dashboardWidgetButtonText}
              onClick={() => {
                const path =
                  selectedProduct?.customRouteName ||
                  paramCase(selectedProduct.heading);

                navigate(`/${formattedLocale}/my-services?service=${path}`);
                dashboardCTAsButtonClick({
                  page_locale: formattedLocale,
                  journey_type: "Dashboard Widget Step 4",
                  event_label: selectedProduct.dashboardWidgetButtonText
                });
              }}
            />
          </div>
        </DashboardWidget.ProductRedirectButtonContainer>
      )}
    </DashboardWidget.CustomStepWrapper>
  );
};
