import { DefaultTheme } from "styled-components";

import Dark from "./ExploreTile.dark.theme";
import Default from "./ExploreTile.default.theme";
import { ExploreTileThemeType } from "./ExploreTile.theme.types";

const getLocalTheme = (theme: DefaultTheme): ExploreTileThemeType => {
  switch (theme.name) {
    // @ts-ignore
    case "WS10Dark":
      return Dark(theme);
    default:
      return Default(theme);
  }
};

export default getLocalTheme;
