import * as React from "react";
import { SVGProps } from "react";

import { backgroundOffsetX } from "../Jarvis";

export interface InnerArcsProps extends SVGProps<SVGSVGElement> {
  isDark?: boolean;
}
const InnerArcs = ({ isDark, ...props }: InnerArcsProps) => (
  <svg
    width="100%"
    style={{
      position: "absolute",
      left: `-${backgroundOffsetX}`,
      animation: "rotate_anti 3.5s ease-in-out 1",
      animationFillMode: "forwards"
    }}
    height="893px"
    viewBox="0 0 892 893"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>InnerNew</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.72"
      strokeLinecap="round"
    >
      <g
        id="Dark"
        transform="translate(-276.000000, -115.000000)"
        stroke={isDark ? "#555555" : "#B9B9B9"}
      >
        <g id="Group-4" transform="translate(282.662047, 123.802095)">
          <g
            id="InnerNew"
            transform="translate(437.454064, 437.428693) scale(-1, 1) rotate(126.000000) translate(-437.454064, -437.428693) translate(124.337953, 124.197905)"
          >
            <path
              d="M271.232222,624.4987 C278.090523,625.807285 292.215832,626.461578 313.608147,626.461578 C486.265657,626.461578 626.232222,486.294596 626.232222,313.389862 C626.232222,225.115473 589.750111,145.374139 531.061163,88.4615777"
              id="Oval"
              strokeWidth="13.96"
            />
            <path
              d="M447.14323,452.5 C450.660543,433.706925 452.5,414.322055 452.5,394.507146 C452.5,221.638072 312.496187,81.5 139.792732,81.5 C53.3518019,81.5 -24.8973194,116.606938 -81.5,173.35328"
              id="Oval"
              strokeWidth="9.31"
              transform="translate(185.500000, 267.000000) rotate(-90.000000) translate(-185.500000, -267.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InnerArcs;
