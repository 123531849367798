import { graphql } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StoreHelpers } from "react-joyride";
import { useQuery } from "react-query";
import { useTheme } from "styled-components";

import loadable from "@loadable/component";

import { useAuth } from "../../context/auth/useAuth";
import {
  ISecurityScorecardRootObject,
  getSecurityScoreAssessment,
  haveSScAccount,
  isServer,
  useFetchWithTracking
} from "../../lib";
import "../../pages/index.css";
import { DashboardContainers, Jarvis, SecurityScoreWidget } from "./components";
import DashboardPartner from "./components/DashboardPartner/index";
import {
  DashboardWidget,
  DashboardWidgetStateKeys
} from "./components/DashboardWidget/DashboardWidget";
import * as StyledDashboard from "./components/ExploreTilesDashboard/styles/ExploreTilesDashboard.styles";
import { ContentfulDashboardWidgetStepUnit } from "./queries/queries.types";
import { MainContainer } from "./styles/dashboard.styles";
import { CallbackProps, DashboardType, DecisionType } from "./types";

// TODO Need to make the banner visible after launch.
// import PageNotification from "../../components/PageNotification";
// import * as StyledPageNotification from "../../components/PageNotification/styles/PageNotification.styles";
// import { useAuth } from "../../context/auth/useAuth";

const Tour = loadable(() => import("../../components/Tour"));

const WelcomeTourPopup = loadable(
  () => import("./components/WelcomeTourPopup")
);

const DashboardBanner = loadable(() => import("./components/DashboardBanner"));

export const query = graphql`
  query GetDashboardContent($locale: String!) {
    contentfulSecurityScoreConfirmation(node_locale: { eq: $locale }) {
      title
      description {
        raw
      }
      buttonText
      helperText
      calculatingPageUrl
    }
    contentfulSecurityScoreAvailability(node_locale: { eq: $locale }) {
      description {
        raw
      }
      privacyText {
        raw
      }
      vodafoneServiceSpecificTerm {
        raw
      }
      buttonText
      modalFooterText
      showPrivacyPolicy
      showServiceTerms
    }
    contentfulSecurityScore(node_locale: { eq: $locale }) {
      buttonText
      filledScoreButtonText
      emptyScoreMessage
      subTitle
      title
      successMessage
      providedBySscMessage
      calculatingText
    }
    contentfulTour(node_locale: { eq: $locale }, name: { eq: "Welcome Tour" }) {
      tourSteps {
        content
        disableBeacon
        offset
        title
        target
        placement
      }
      name
      locale {
        back
        next
        last
      }
    }
    contentfulWelcomeTourPopup(node_locale: { eq: $locale }) {
      video {
        file {
          url
        }
      }
      videoThumbnail {
        id
        file {
          url
        }
      }
      heading
      text {
        raw
      }
      mainButton
      secondaryButton
    }
    allContentfulDashboardWidgetStep(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          subHeading
          step
          heading
          skipButtonText
          buttonText
          description {
            description
          }
          explainerTilesCarousel {
            productId
            headingText
            serviceId
            dashboardWidgetTitle
            dashboardWidgetCybershopDescription
            dashboardWidgetCybershopHeading
            mainIcon {
              file {
                url
              }
            }
            mainIconLight {
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulDashboardContainers(
      filter: { pageName: { eq: "dashboard" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          contentId
          heading
          description
          badgeText
          isDarkText
          link
          backgroundAppearance
          linkText
          pillAppearance
        }
      }
    }
    contentfulCardBuilder(node_locale: { eq: $locale }) {
      extraLargeImage {
        gatsbyImageData(quality: 100, width: 1000)
      }
      largeImage {
        gatsbyImageData(quality: 100, width: 700, height: 532)
      }
      mediumImage {
        gatsbyImageData(quality: 100, width: 374)
      }
      smallImage {
        gatsbyImageData(quality: 100, width: 320)
      }
      heading
      childrenContentfulCardBuilderDescriptionTextNode {
        description
      }
      pillText
      childrenContentfulCardBuilderIconsJsonNode {
        internal {
          content
        }
      }
    }
    contentfulCybSafeDashboardTiles(node_locale: { eq: $locale }) {
      activatedUsersTile
      refresherRateTile
      activatedUsersHelpText
    }
    VSDMTileFields: contentfulVsdmDashboard(node_locale: { eq: $locale }) {
      compromisedPieText
      noPasscodePieText
      notEncryptedPieText
    }
    allContentfulScoreConfiguration(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          colour
          rating
          score
          text
          hexCode
        }
      }
    }
    allContentfulServiceTile(
      filter: {
        node_locale: { eq: $locale }
        isSelfServe: { eq: true }
        dashboardWidgetButtonText: { ne: null }
      }
    ) {
      nodes {
        productId
        heading
        icon {
          file {
            url
          }
        }
        darkIcon {
          file {
            url
          }
        }
        customRouteName
        serviceHlId
        dashboardWidgetButtonText
        dashboardWidgetText
        dashboardWidgetHeading
        devicesText
        serviceHlId
      }
    }
    lookoutPackageTile: contentfulPackageTile(
      packageTile: { eq: "Lookout Mobile Security" }
      node_locale: { eq: $locale }
    ) {
      packageTile
      widgetInfo
      buttonName
      header
      widgetSubText
    }
    contentfulCarousel(
      carouselId: { eq: "partners" }
      node_locale: { eq: $locale }
    ) {
      header
      description
      buttonText
      buttonLink
      node_locale
      carousel {
        dashboardWidgetCybershopHeading
        headingText
        partnerWidgetTitle
        partnerWidgetDescription
        mainIcon {
          file {
            url
          }
        }
        mainIconLight {
          file {
            url
          }
        }
      }
    }
  }
`;

const getLastStep = () =>
  !isServer ? Number(localStorage.getItem("lastDashboardWidgetStep")) : 0;

const DashboardPage = ({
  data,
  tourStarted,
  pageContext: { formattedLocale, locale }
}: DashboardType) => {
  const theme = useTheme();
  // TODO Need to make the banner visible after launch.
  const auth = useAuth();
  const company = auth?.authState?.user?.company;
  const { fetchCyberhubMiddlewareWithTracking } = useFetchWithTracking();

  const [canBeFetched, setCanBeFetched] = useState<boolean>(haveSScAccount);

  const [widgetStep, setWidgetStep] = useState<number>(getLastStep());

  const [tour, setTour] = useState({ isOpen: false, runTour: false });

  const isDark = theme.name !== "WS10";

  const setLastStep = (step: number) => {
    setWidgetStep(step);
    localStorage.setItem("lastDashboardWidgetStep", String(step));
  };

  const isCompanyNotFoundError = (error: unknown): boolean => {
    return (
      typeof error === "object" &&
      error !== null &&
      "message" in error &&
      (error as { message: string }).message === `company not found: ${company}`
    );
  };

  const setHasScore = () => {
    localStorage.setItem("haveSScAccount", "true");
    setCanBeFetched(true);
  };

  const handleOnError = (error: unknown) => {
    if (isCompanyNotFoundError(error)) {
      localStorage.setItem("haveSScAccount", "true");
      try {
        Promise.allSettled([
          fetchCyberhubMiddlewareWithTracking("scores/portfolio", {
            method: "POST"
          }),
          fetchCyberhubMiddlewareWithTracking("scores/individual", {
            method: "POST"
          })
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const {
    contentfulTour,
    contentfulWelcomeTourPopup,
    allContentfulDashboardContainers,
    contentfulCardBuilder,
    contentfulCybSafeDashboardTiles,
    VSDMTileFields,
    contentfulCarousel,
    lookoutPackageTile
  } = data;

  const {
    isLoading,
    data: sscData,
    isFetched,
    refetch
  } = useQuery<ISecurityScorecardRootObject>({
    queryKey: "securityScoreCard",
    queryFn: () => getSecurityScoreAssessment(),
    onSuccess: () => {
      if (!haveSScAccount) setHasScore();
      if (widgetStep < 2) setWidgetStep(2);
    },
    onError: handleOnError,
    enabled: canBeFetched
  });

  useEffect(() => {
    if (tourStarted) {
      /**  Dark Mode notification has been closed. */
      setTour((prevState) => ({ ...prevState, isOpen: true }));
    }
  }, [tourStarted]);

  const getUserCurrentWidgetStep = useCallback(
    (score: boolean) => {
      const persistedStep = getLastStep();

      // early return, meaning we won't get overlaps between
      // being in step 3 and step 2

      if (persistedStep && widgetStep) return widgetStep;
      // if user got score, jump one

      if (score) {
        setLastStep(2);
        return 2;
      }

      return 1;
    },
    [widgetStep]
  );

  const handleWidgetFinish = () => {
    setLastStep(4);
  };

  const dashboardWidgetStep = useMemo(() => {
    const currentStepNumber = getUserCurrentWidgetStep(Boolean(sscData?.score));

    const currentStepFields = data.allContentfulDashboardWidgetStep.edges.find(
      ({ node }: { node: ContentfulDashboardWidgetStepUnit }) =>
        node.step === currentStepNumber
    );

    return {
      step: currentStepNumber as DashboardWidgetStateKeys,
      fields: currentStepFields,
      serviceTiles: {
        allContentfulServiceTile: {
          nodes: data.allContentfulServiceTile.nodes
        }
      }
    };
  }, [
    getUserCurrentWidgetStep,
    sscData?.score,
    data.allContentfulDashboardWidgetStep.edges,
    data.allContentfulServiceTile.nodes
  ]);

  const getTourDecision = useCallback(
    ({ decision }: DecisionType) => {
      if (!decision) {
        /**  Closes the modal and dont show Tour */
        localStorage.setItem("did-tour", "true");
        setTour({ ...tour, isOpen: false });
      } else {
        /**  Closes Modal and open the Tour */
        setTour({ isOpen: false, runTour: true });
      }
    },
    [tour]
  );

  const welcomeTourCallback = ({
    index,
    action,
    lifecycle
  }: Partial<CallbackProps>) => {
    if (action === "close") {
      setTour({ isOpen: false, runTour: false });
      return;
    }
    if (index === 0 && lifecycle === "complete" && action === "prev")
      /** Open Welcome Tour Pop-up again. */
      setTour({ runTour: false, isOpen: true });

    /** Dont show Tour on next login*/
    if (action === "reset") {
      setTour({ isOpen: false, runTour: false });
      localStorage.setItem("did-tour", "true");
    }
  };

  const getHelpers = useCallback(
    (helpers: StoreHelpers) => {
      helpers.close = () => {
        setTour({
          ...tour,
          runTour: false,
          isOpen: false
        });
      };
    },
    [tour]
  );

  const TourSteps = useMemo(
    () =>
      contentfulTour.tourSteps.map((tourStep: any) => ({
        ...tourStep,
        locale: contentfulTour.locale
      })),
    [contentfulTour.locale, contentfulTour.tourSteps]
  );

  return (
    <MainContainer isDark={isDark}>
      <Tour
        continuous
        scrollOffset={300}
        containerId="dashboard"
        isClosableOnClick={false}
        show={tour.runTour}
        inverse={isDark}
        steps={TourSteps}
        callback={(data: CallbackProps) => welcomeTourCallback(data)}
        getHelpers={getHelpers}
        spotlightClicks={false}
        theme={theme as any}
      />
      {/* TODO Need to make the banner visible after launch. */}
      {/* {auth?.authState?.isAuthenticated ? (
        <StyledPageNotification.NotificationSectionDashboard isDark={isDark}>
          <PageNotification />
        </StyledPageNotification.NotificationSectionDashboard>
      ) : null} */}
      <Jarvis isDark={isDark}>
        <StyledDashboard.DashboardSection>
          <SecurityScoreWidget
            {...data?.contentfulSecurityScore}
            securityScoreCardConfigurationData={
              data.allContentfulScoreConfiguration
            }
            availabilityFields={data.contentfulSecurityScoreAvailability}
            confirmationContentfulFields={
              data.contentfulSecurityScoreConfirmation
            }
            locale={locale}
            formattedLocale={formattedLocale}
            isLoading={isLoading}
            data={sscData}
            isFetched={isFetched}
            refetch={refetch}
            setCanBeFetched={setCanBeFetched}
          />
          <DashboardWidget
            isDark={isDark}
            lookoutFields={lookoutPackageTile}
            cybSafeTileFields={contentfulCybSafeDashboardTiles}
            VSDMTileFields={VSDMTileFields}
            setFinishedWidget={handleWidgetFinish}
            formattedLocale={formattedLocale}
            currentStep={dashboardWidgetStep.step}
            fields={dashboardWidgetStep?.fields}
            serviceTiles={dashboardWidgetStep?.serviceTiles}
            onSkip={(step: number) => {
              setLastStep(step);
            }}
          />
        </StyledDashboard.DashboardSection>
      </Jarvis>

      <DashboardContainers
        data={allContentfulDashboardContainers}
        formattedLocale={formattedLocale}
      />
      <DashboardBanner data={contentfulCardBuilder} isDark={isDark} />

      <WelcomeTourPopup
        isDark={isDark}
        isOpen={tour.isOpen && !tour.runTour}
        takeDecision={getTourDecision}
        data={contentfulWelcomeTourPopup}
      />
      <DashboardPartner data={contentfulCarousel} isDark={isDark} />
    </MainContainer>
  );
};

export default DashboardPage;
