import styled, { css } from "styled-components";

import { backgroundOffsetX } from "../../Jarvis/Jarvis";

export const DashboardSection = styled.div`
  ${(props) => css`
    position: absolute;
    height: 1110px;
    width: 100%;
    justify-content: center;
    display: flex;

    @media (max-width: ${props.theme.breakpoints.md}px) {
      height: 100%;
      position: initial;
      display: grid;
      grid-gap: 5em;
    }

    @media (min-width: ${props.theme.breakpoints.lg}px) {
      left: -${backgroundOffsetX};
    }
  `}
`;

export const DashboardItem = styled.div`
  ${(props) => css`
    display: none;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      :nth-child(1) {
        order: 6;
      }
      :nth-child(2) {
        order: 2;
      }
      :nth-child(3) {
        order: 4;
      }
      :nth-child(4) {
        order: 5;
        flex-basis: 100%;
        justify-content: center;
        display: none;
      }
      :nth-child(5) {
        order: 3;
        @media only screen and (min-width: 1025px) {
          flex-basis: 50%;
        }
      }
      :nth-child(6) {
        order: 1;
      }
    }

    @media only screen and (min-width: 1025px) and (max-width: 1115px) {
      & > a,
      & > div {
        width: 340px;
      }
      :nth-child(4) {
        padding-top: 12rem;
      }
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  `}
`;
