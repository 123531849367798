import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.725em;
`;

export const EmptyWidgetAsBackgroundWrapper = styled.div`
  position: absolute;
  z-index: -1;
`;

export const DescriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.25em;
`;

export const ModalContainer = styled.div`
  margin-top: 5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 512px;

  & button {
    background-color: #e60000;
    margin-bottom: 1.2em;
  }
`;
