import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

const INITIAL_LEFT_POSITION = 699;
const INITIAL_TOP_POSITION = 280;
const INITIAL_WIDTH = 460;
const BORDER_RADIUS = 6;
const SCREEN_SIZE_LARGE = 1440;
const SCREEN_SIZE_MEDIUM = 1024;
const SCREEN_SIZE_TABLET = 768;
const SCALE_FACTOR = 0.5;

export const Container = styled.div`
  ${(props) => css`
    & div[data-selector="-dashboard-widget-container"] {
      background: ${props.theme.color.monochrome2.default}66;
      height: fit-content;

      & > div {
        height: inherit;
        // When the viewport width is less than 764px, set a height for the widget so the background (arcs) don't move when content doing responsive layout is added.
        @media (max-width: 764px) {
        }
      }
    }

    position: relative;
    left: ${INITIAL_LEFT_POSITION}px;
    top: ${INITIAL_TOP_POSITION}px;
    width: ${INITIAL_WIDTH}px;
    font-size: 14px;
    max-height: 575px;
    height: 100%;
    border-radius: ${BORDER_RADIUS}px;
    margin-right: 2em;
    // This adjustment helps maintain the relative position of the widget as the viewport width decreases.
    @media (min-width: ${SCREEN_SIZE_LARGE}px) {
      font-size: 16px;
    }

    @media (max-width: ${SCREEN_SIZE_LARGE}px) {
      left: calc(
        ${INITIAL_LEFT_POSITION}px + (100vw - ${SCREEN_SIZE_LARGE}px) *
          ${SCALE_FACTOR}
      );
    }

    // This helps ensure the rest of the layout is visible and usable on smaller screens.
    @media (max-width: ${SCREEN_SIZE_TABLET}px) {
      & div[data-selector="-dashboard-widget-container"] {
        max-width: 100vw;
        width: 100vw;
      }

      margin-right: 0;
      width: 100%;
      position: initial;
      top: unset;
      left: unset;
    }
    // This helps ensure the rest of the layout is visible and usable on smaller screens.
    @media (max-width: ${SCREEN_SIZE_MEDIUM}px) {
      & div[data-selector="-dashboard-widget-container"] {
        max-width: 100vw;
        margin: 0 1em 0 1em;
      }
      min-height: 575px;
      left: calc(
        ${INITIAL_LEFT_POSITION}px + (100vw - ${SCREEN_SIZE_LARGE}px) *
          ${SCALE_FACTOR}
      );
    }
  `}
`;

export const Header = styled.div(
  ({ haveIcon }: { haveIcon: boolean }) => css`
    margin-bottom: 1.25em;

    ${haveIcon &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
    `}
  `
);

export const ImageAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25em;
  margin: 1em 0;
`;

export const CarouselItemWrapper = styled.div`
  padding-inline: 3.25em;

  & picture[data-selector="carousel-image"] {
    display: block;
    max-width: 60px;
    max-height: 60px;
    width: 60px;
    height: 60px;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const ScoreCardWrapper = styled.div`
  min-height: 530px;
`;

export const SkipButtonContainer = styled.div`
  cursor: pointer;
  gap: 0.5em;
  padding-left: 0.5em;
  display: flex;
  align-items: center;
  & > span {
    padding-bottom: 0.9em;
  }
`;

export const StepBody = styled.div(
  ({ shouldMargin }: { shouldMargin?: boolean }) => css`
    height: 310px;

    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      padding-top: 1.75em;
    }

    ${shouldMargin &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
        margin-bottom: 3em;
      }
    `}
  `
);

export const ButtonContainer = styled.div`
  margin-top: 2.5em;
  justify-content: center;
  display: flex;
`;

export const CustomStepWrapper = styled.div`
  height: 100%;
`;

export const ProductRedirectButtonContainer = styled.div`
  display: flex;
  gap: 1em;
  flex-flow: column wrap;
  align-items: center;
  height: 6em;
  justify-content: center;
  @media (max-width: ${SCREEN_SIZE_MEDIUM}px) {
    height: ${(props: { height?: number }) =>
      props.height ? props.height : "9.0625"}em;
    padding-top: 14px;
  }
  & > div {
    display: flex;
    justify-content: center;
  }
`;

export const ServicesListWrapper = styled.div`
  width: 100%;
  z-index: 5;
  padding-bottom: 0.9375em;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: 100%;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 38px;
    background: ${(props) => props.theme.color.monochrome2.default}66;

    z-index: 4;
    position: absolute;
    top: 175px;
    filter: blur(5px);
    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      top: 155px;
    }
  }
`;

export const CollapsibleContainerWrapper = styled.div`
  height: 320px;
  overflow-y: scroll;
  border-bottom: 1px solid
    ${({ theme }: { theme: Theme }) => theme.color.monochrome4.default};
  padding-inline: 1em;
  margin-bottom: ${(props: { theme: Theme }) =>
    props.theme.spacing.responsive[2]}px;

  & button[data-selector="collapsable-header"] {
    border-bottom: 0.0625em solid;
    height: 3em;
    padding: 0.575em 0 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    height: 280px;
    padding-inline: 0;
  }
`;

export const ProductWidgetWrapper = styled.div`
  padding: 0.525em 0;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375em;
`;

export const RemovePager = styled.div`
  & div[data-selector="dashboard-carousel"] ul {
    display: none;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const VSDMTileWrapper = styled.div`
  margin: 6px 0 20px;
`;

export const VSDMProgressBarWrapper = styled.div`
  margin-bottom: 12px;

  > div {
    > div {
      > div:last-child {
        div[role="progressbar"] {
          background-color: #f06666;
        }
      }
    }
  }
`;

export const VSDMItemTextWrapper = styled.div`
  display: flex;
`;

export const VSDMItemTextSignificantPart = styled.div`
  font-weight: 700;
`;
