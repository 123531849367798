import styled from "styled-components";

export const ButtonWrapper = styled.div(
  (props) => `
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    button {
        margin: ${props.theme.spacing.fixed[2]}px 0;
    }
    
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
        flex-direction: row;
    }
`
);
