import * as React from "react";
import { SVGProps } from "react";

import { backgroundOffsetX } from "../Jarvis";

export interface OuterArcsProps extends SVGProps<SVGSVGElement> {
  isDark?: boolean;
}

const OuterArcs = ({ isDark, ...props }: OuterArcsProps) => (
  <svg
    width="100%"
    height="838px"
    viewBox="0 0 407 716"
    style={{
      position: "absolute",
      left: `-${backgroundOffsetX}`,
      animation: "rotate 3.5s ease-in-out 1",
      animationFillMode: "forwards"
    }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Outer</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.699999988"
      strokeLinecap="round"
    >
      <g
        id="Dark"
        transform="translate(-515.000000, -205.000000)"
        stroke={isDark ? "#555455" : "#86E7EF"}
        strokeWidth="15"
      >
        <g id="Group-4" transform="translate(282.662047, 123.802095)">
          <g
            id="Outer"
            transform="translate(437.556821, 437.448057) scale(-1, -1) translate(-437.556821, -437.448057) translate(243.337953, 89.197905)"
          >
            <path
              d="M372.560622,59.4455962 C366.749151,38.8221933 358.426372,19.2515067 347.943496,1.08488518"
              id="Oval"
              transform="translate(360.252059, 30.265241) rotate(-40.000000) translate(-360.252059, -30.265241) "
            />
            <path
              d="M52.5,644.000305 C37.9104215,665.492797 26.359644,689.386157 18.5,715.000305"
              id="Oval"
              transform="translate(35.500000, 679.500305) rotate(-90.000000) translate(-35.500000, -679.500305) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default OuterArcs;
