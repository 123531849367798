import styled, { css } from "styled-components";

export const LayoutContainer = styled.div(
  (props) => css`
    background-color: ${props.theme.color.monochrome2.default};
    width: 100%;
    padding: 64px 16px;
    display: flex;
    justify-content: center;

    @media (max-width: ${props.theme.breakpoints.md}px) {
      padding: 16px;
    }
  `
);

export const CardBuilderWrapper = styled.div(
  (props) => css`
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    div[data-component-name="Container"] {
      width: 100%;
      margin-bottom: 12px;
      background-color: ${props.theme.color.monochrome1.default};
    }

    @media (min-width: ${props.theme.breakpoints.md}px) {
      flex-direction: row;
      div[data-component-name="Container"] {
        max-width: 350px;
      }
    }

    @media (min-width: 1024px) {
      div[data-component-name="Container"] {
        max-width: 440px;
        margin-bottom: 0;
      }
    }

    @media (min-width: 1124px) {
      div[data-component-name="Container"] {
        max-width: 540px;
        margin-bottom: 0;
      }
    }
  `
);
