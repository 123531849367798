import React from "react";

import { Container } from "@source-web/container";
import { Loader } from "@source-web/loader";

import { LoaderWrapper } from "../../../../components/UpdatesWidget/styles/UpdatesWidget.style";
import { handleSubscriptionIds, useEventSource } from "../../../../lib";
import { IMiddlewareSubscription } from "../../../../lib/services/marketplaceService/subscriptions.types";
import { ContentfulPackageTile } from "../../../profile/types";
import {
  ContentfulCybSafeDashboardTiles,
  ContentfulDashboardWidgetStepUnit,
  ContentfulServiceTileWidget,
  ContentfulVSDMDashboardTile
} from "../../queries/queries.types";
import { CybershopOverview } from "./components/CybershopOverview";
import { MyServices } from "./components/MyServices";
import { SSCWelcome } from "./components/SSCWelcome";
import { ServicesOverview } from "./components/ServicesOverview";
import * as DashboardWidgetStyles from "./styles/DashboardWidget.styles";

export type DashboardWidgetStateKeys = 0 | 1 | 2 | 3 | 4;

export interface DashboardWidgetProps {
  currentStep?: DashboardWidgetStateKeys;
  fields:
    | {
        node: ContentfulDashboardWidgetStepUnit;
      }
    | undefined;
  onSkip: (step: number) => void;
  isDark: boolean;
  formattedLocale: string;
  serviceTiles: ContentfulServiceTileWidget | undefined;
  setFinishedWidget?: () => void;
  cybSafeTileFields: ContentfulCybSafeDashboardTiles["contentfulCybSafeDashboardTiles"];
  lookoutFields: ContentfulPackageTile;
  VSDMTileFields: ContentfulVSDMDashboardTile;
}

export const DashboardWidget = ({
  onSkip,
  currentStep = 0,
  isDark,
  fields,
  serviceTiles,
  setFinishedWidget,
  formattedLocale,
  cybSafeTileFields,
  VSDMTileFields,
  lookoutFields
}: DashboardWidgetProps) => {
  const { data, isLoading, isError } = useEventSource<IMiddlewareSubscription>(
    "marketplace/subscriptions?status=ACTIVE",
    {
      isSingleEntity: false
    }
  );

  const availableSubscriptionIds = handleSubscriptionIds(data);

  const componentsByState: Record<
    DashboardWidgetStateKeys,
    React.ReactElement | string
  > = {
    0: (
      <LoaderWrapper>
        <Loader
          appearance="primary"
          text={{
            text: ""
          }}
        />
      </LoaderWrapper>
    ),
    1: <SSCWelcome isDark={isDark} fields={fields?.node} />,
    2: (
      <ServicesOverview
        isDark={isDark}
        handleSkipTour={onSkip}
        fields={fields?.node}
        serviceTiles={serviceTiles}
        formattedLocale={formattedLocale}
      />
    ),
    3: (
      <CybershopOverview
        isDark={isDark}
        setFinishedWidget={setFinishedWidget}
        fields={fields?.node}
        formattedLocale={formattedLocale}
        availableSubscriptionIds={availableSubscriptionIds}
      />
    ),
    4: (
      <MyServices
        cybSafeTileFields={cybSafeTileFields}
        lookoutFields={lookoutFields}
        VSDMTileFields={VSDMTileFields}
        fields={fields?.node}
        serviceTiles={serviceTiles}
        isDark={isDark}
        formattedLocale={formattedLocale}
        isError={isError}
        isLoading={isLoading}
        availableSubscriptionIds={availableSubscriptionIds}
      />
    )
  };

  return (
    <DashboardWidgetStyles.Container>
      <Container
        paddingLevel={4}
        elevation
        dataSelectorPrefix="DashboardWidgetContainer"
      >
        {componentsByState[currentStep]}
      </Container>
    </DashboardWidgetStyles.Container>
  );
};
