import * as React from "react";
import { ImgHTMLAttributes } from "react";
import ReactPlayer from "react-player";
import { BaseReactPlayerProps } from "react-player/base";
import { useTheme } from "styled-components";

//fix this import error later on
//@ts-ignore
import SecondaryIcon from "./assets/Video-Desktop-Red.svg";
//@ts-ignore
import PrimaryIcon from "./assets/Video-Desktop-White.svg";
//@ts-ignore
import SecondaryIconMobile from "./assets/Video-Mobile-Red.svg";
//@ts-ignore
import PrimaryIconMobile from "./assets/Video-Mobile-White.svg";

interface PlayIconProps extends ImgHTMLAttributes<HTMLImageElement> {
  appearance?: "primary" | "secondary";
}

export const PlayIcon = ({ appearance, ...props }: PlayIconProps) => {
  const theme = useTheme();
  const icons = {
    sm: appearance === "primary" ? PrimaryIconMobile : SecondaryIconMobile,
    lg: appearance === "primary" ? PrimaryIcon : SecondaryIcon
  };
  return (
    <img
      {...props}
      aria-label="play"
      alt="play button"
      title="play"
      srcSet={`${icons.sm} 56w, ${icons.lg} 64w`}
      sizes={`(max-width: ${theme.breakpoints.md}px) 56px, 64px`}
    />
  );
};
export interface ResponsiveVideoPlayerProps extends BaseReactPlayerProps {
  /** The appearance of the play button */
  appearance?: "primary" | "secondary";
  /** Properties passed to the play icon */
  iconProps?: ImgHTMLAttributes<HTMLImageElement>;
  /** The preview image shown before the video starts */
  thumbnail?: string;
}

const ResponsiveVideoPlayer = ({
  appearance = "primary",
  iconProps,
  thumbnail,
  ...props
}: ResponsiveVideoPlayerProps) => {
  return (
    <ReactPlayer
      {...props}
      light={thumbnail || true}
      playIcon={<PlayIcon {...iconProps} appearance={appearance} />}
    />
  );
};
export default ResponsiveVideoPlayer;
