import React, { useState } from "react";

import { Checkbox } from "@source-web/checkbox";
import { Paragraph } from "@source-web/paragraph";

import { ContentfulRichText } from "../../../../../../../components/ContentfulRichText";
import { PrimaryButton } from "../../../../../../../components/PrimaryButton";
import { dashboardCTAsButtonClick } from "../../../../../../../lib";
import { ContentfulSecurityScoreAvailability } from "../../../../../queries/queries.types";
import { CheckBoxText, ModalContainer } from "./CyberScoreAvailability.styles";

interface CyberScoreProps {
  toggleAvailability: () => void;
  fields: ContentfulSecurityScoreAvailability["contentfulSecurityScoreAvailability"];
  formattedLocale: string;
}
export function CyberScoreAvailability({
  toggleAvailability,
  fields,
  formattedLocale
}: CyberScoreProps) {
  const {
    modalFooterText,
    description,
    buttonText,
    privacyText,
    vodafoneServiceSpecificTerm,
    showPrivacyPolicy,
    showServiceTerms
  } = fields;

  const [isPrivacyPolicyChecked, checkPrivacyPolicy] = useState(false);
  const [isVodafoneServiceSpecificTerm, checkVodafoneServiceSpecificTerm] =
    useState(false);

  const isButtonEnabled = () => {
    if (showPrivacyPolicy && showServiceTerms) {
      return isPrivacyPolicyChecked && isVodafoneServiceSpecificTerm;
    } else if (showPrivacyPolicy) {
      return isPrivacyPolicyChecked;
    } else if (showServiceTerms) {
      return isVodafoneServiceSpecificTerm;
    }
    return false;
  };

  return (
    <ModalContainer>
      <Paragraph justify="center">
        <ContentfulRichText text={description.raw} />
      </Paragraph>
      {/*Depending on the market we may need to change the tick boxes and what they link out to */}
      {showPrivacyPolicy && (
        <Checkbox
          name="availabilityCheckbox"
          onChange={(event) => {
            checkPrivacyPolicy(event.target.checked);
          }}
          checked={isPrivacyPolicyChecked}
          value="checkboxPrivacyPolicy"
          id="privacyPolicy"
          dataAttributes={{ "data-testid": "checkboxPrivacyPolicy" }}
        >
          <CheckBoxText>
            <ContentfulRichText text={privacyText.raw} />
          </CheckBoxText>
        </Checkbox>
      )}
      {showServiceTerms && (
        <Checkbox
          name="availabilityCheckbox"
          onChange={(event) => {
            checkVodafoneServiceSpecificTerm(event.target.checked);
          }}
          checked={isVodafoneServiceSpecificTerm}
          value="checkboxServiceSpecificTerm"
          id="serviceSpecificTerm"
          dataAttributes={{ "data-testid": "checkboxPrivacyPolicy" }}
        >
          <CheckBoxText>
            <ContentfulRichText text={vodafoneServiceSpecificTerm.raw} />
          </CheckBoxText>
        </Checkbox>
      )}
      <Paragraph>{modalFooterText}</Paragraph>
      <div>
        <PrimaryButton
          text={buttonText}
          state={isButtonEnabled() ? undefined : "disabled"}
          onClick={() => {
            toggleAvailability();
            dashboardCTAsButtonClick({
              page_locale: formattedLocale,
              journey_type: "Get your FREE cyber score",
              event_label: buttonText
            });
          }}
          width="auto"
        />
      </div>
    </ModalContainer>
  );
}
