import React, { useMemo } from "react";

import { Spacing } from "@source-web/spacing";
import { Span } from "@source-web/span";

import { Loader } from "../../../../../components";
import { AdviseNotification } from "../../../../../components/AdviseNotification";
import { ProgressBar } from "../../../../../components/ProgressBar";
import { errorMessages } from "../../../../../lib/";
import { RefreshRateCard } from "../../../../servicesPage/components/CybSafe/components/RefresherRate";
import { RefresherContainer } from "../../../../servicesPage/components/CybSafe/styles/CybSafe.styles";
import {
  AwarenessOverview,
  AwarenessRates,
  useCybSafeData
} from "../../../../servicesPage/hooks/useCybSafeData";
import { DashboardWidgetProps } from "../DashboardWidget";
import * as DashboardWidgetStyles from "../styles/DashboardWidget.styles";

interface CybsafeDashboardWidgetProps {
  cybSafeTileFields: DashboardWidgetProps["cybSafeTileFields"];
}

function renderUsers(
  users: AwarenessOverview,
  activatedUsersTile: string,
  activatedUsersHelpText: string,
  handlePercentage: () => number
): React.ReactNode | Record<string, unknown> {
  return (
    users && (
      <>
        <DashboardWidgetStyles.ProgressBarWrapper>
          <Span>{activatedUsersTile}&#58;</Span>
          <Span>
            <div>
              <Span weight={3}>{users?.awarenessOverview.usersCompleted}</Span>
              &#47;
              <Span weight={3}>
                {users.awarenessOverview.usersTotal} {activatedUsersHelpText}
              </Span>
            </div>
          </Span>
        </DashboardWidgetStyles.ProgressBarWrapper>
        <ProgressBar
          appearance="secondary"
          inverse
          value={handlePercentage()}
          srLabel="Progress bar"
        />
      </>
    )
  );
}

function renderRefresherRates(
  rates: AwarenessRates,
  refresherRateTile: string,
  cybSafeTileFields: {
    activatedUsersTile: string;
    refresherRateTile: string;
    activatedUsersHelpText: string;
    sscHeading: string;
  }
): React.ReactNode | Record<string, unknown> {
  return (
    rates && (
      <Spacing
        spacingLevel={{
          top: 3
        }}
      >
        <RefresherContainer hasBackground={false}>
          <Span weight={3}>{refresherRateTile}&#58;</Span>

          <RefreshRateCard
            contentfulData={cybSafeTileFields}
            isWidgetVersion
            awarenessRatesData={rates}
          />
        </RefresherContainer>
      </Spacing>
    )
  );
}

export function CybsafeDashboardWidget({
  cybSafeTileFields
}: CybsafeDashboardWidgetProps) {
  const { data, isLoading, isError } = useCybSafeData({
    enableCulture: false,
    enablePhishing: false,
    enableBehaviorScore: false,
    enableAwarenessRates: true,
    enableAwarenessOverview: true
  });

  const { activatedUsersTile, refresherRateTile, activatedUsersHelpText } =
    cybSafeTileFields;

  const renderStates = useMemo(() => {
    if (isError || (!data && !isLoading)) {
      return (
        <AdviseNotification state="error" text={errorMessages.unexpected} />
      );
    }
    if (isLoading) {
      return <Loader text={{ text: "" }} isSmall height="auto" />;
    }

    const users = data?.awarenessOverview as AwarenessOverview;
    const rates = data?.awarenessRates as AwarenessRates;

    const handlePercentage = () =>
      (100 / users?.awarenessOverview.totalLicences) *
      users?.awarenessOverview.usersCompleted;

    return (
      <DashboardWidgetStyles.ProductWidgetWrapper>
        <>
          {renderUsers(
            users,
            activatedUsersTile,
            activatedUsersHelpText,
            handlePercentage
          )}
        </>

        <>{renderRefresherRates(rates, refresherRateTile, cybSafeTileFields)}</>
      </DashboardWidgetStyles.ProductWidgetWrapper>
    );
  }, [
    isError,
    data,
    isLoading,
    activatedUsersTile,
    activatedUsersHelpText,
    refresherRateTile,
    cybSafeTileFields
  ]);

  return <> {renderStates}</>;
}
