import React from "react";

import { Paragraph } from "@source-web/paragraph";

import { ContentfulRichText } from "../../../../../../components/ContentfulRichText";
import { dashboardCTAsButtonClick, isServer } from "../../../../../../lib/";
import {
  ContentfulSecurityScoreAvailability,
  ContentfulSecurityScoreConfirmation
} from "../../../../queries/queries.types";
import { CyberScoreAvailability } from "./CyberScoreAvailability";
import CyberScorePopup from "./CyberScorePopup";
import {
  IntroductionHelperText,
  IntroductionParagraph
} from "./styles/SecurityScoreIntroduction.styles";

export interface SecurityScoreFormPageProps {
  isCyberScoreAvailable?: boolean;
  handleGetCyberScoreClick: () => void;
  fields: ContentfulSecurityScoreAvailability["contentfulSecurityScoreAvailability"];
  confirmationContentfulFields: ContentfulSecurityScoreConfirmation["contentfulSecurityScoreConfirmation"];
  formattedLocale: string;
}

export function SecurityScoreIntroduction({
  isCyberScoreAvailable = false,
  handleGetCyberScoreClick,
  fields,
  confirmationContentfulFields,
  formattedLocale
}: SecurityScoreFormPageProps) {
  return isCyberScoreAvailable ? (
    <CyberScorePopup
      heading={{
        justify: "center",
        text: confirmationContentfulFields.title,
        size: 3
      }}
      confirmBtnProps={{
        text: confirmationContentfulFields.buttonText,
        appearance: "primary",
        onClick: () => {
          if (!isServer) {
            window.open(confirmationContentfulFields.calculatingPageUrl);
          }
          dashboardCTAsButtonClick({
            page_locale: formattedLocale,
            journey_type: confirmationContentfulFields.title,
            event_label: confirmationContentfulFields.buttonText
          });
        }
      }}
    >
      <IntroductionParagraph>
        <Paragraph justify="center">
          <ContentfulRichText
            text={confirmationContentfulFields.description.raw}
          />
        </Paragraph>
      </IntroductionParagraph>

      <IntroductionHelperText>
        <Paragraph weight={1} size={1}>
          {confirmationContentfulFields.helperText}
        </Paragraph>
      </IntroductionHelperText>
    </CyberScorePopup>
  ) : (
    <CyberScoreAvailability
      fields={fields}
      toggleAvailability={() => handleGetCyberScoreClick()}
      formattedLocale={formattedLocale}
    />
  );
}
