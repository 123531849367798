import { ExploreTileThemeType } from "./ExploreTile.theme.types";

const exploreTileLocal = (theme: any): ExploreTileThemeType => {
  return {
    background: `linear-gradient(90deg, rgba(0,0,0,0.39) 0%, #2B2B2B 100%)`,
    borderColor: "#222222",
    imageBackground: theme.color.monochrome1.default
  };
};

export default exploreTileLocal;
