import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  gap: 1.5em;
  & h3 {
    margin-bottom: 1em;
  }
  & > p {
    text-align: left;
  }
  & button {
    background-color: #e60000;
    margin-bottom: 1.2em;
  }

  & > div:last-of-type {
    display: flex;
    justify-content: center;
  }
`;

export const CheckBoxText = styled.div`
  text-underline-offset: 2px;
  p {
    font-size: 14px;
  }
`;
