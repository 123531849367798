import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

interface StyledProps {
  theme: Theme;
  isDarkText?: boolean;
  keepColor?: string;
}

export const ParagraphWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const PillWrapper = styled.div`
  ${({ theme, keepColor }: StyledProps) => css`
    margin-bottom: 18px;

    ${keepColor === "alt2" &&
    theme.name === "WS10" &&
    css`
      span[data-component-name="Pill"] {
        background: ${theme.color.monochrome6.default};
        color: ${theme.inverseColor.monochrome6.default};
      }
    `}

    ${keepColor === "alt1" &&
    theme.name === "WS10Dark" &&
    css`
      span[data-component-name="Pill"] {
        color: ${theme.inverseColor.monochrome1.default};
      }
    `}
  `}
`;
