import React, { FC } from "react";

import {
  AppearanceKeys as BackgroundKeys,
  Container
} from "@source-web/container";
import { Heading } from "@source-web/heading";
import { LinkWithIcon } from "@source-web/link-with-icon";
import { Paragraph } from "@source-web/paragraph";
import { Pill, AppearanceKeys as PillKeys } from "@source-web/pill";

import { dashboardLinksClick } from "../../lib";
import { ContentfulDashboardContainer } from "../../pageTemplates/dashboard/queries/queries.types";
import { ParagraphWrapper, PillWrapper } from "./styles/Card.styles";

interface CardProps {
  item: ContentfulDashboardContainer;
  formattedLocale: string;
}

export const Card: FC<CardProps> = ({ item, formattedLocale }) => (
  <Container
    appearance={item.backgroundAppearance as BackgroundKeys}
    id={item.contentId}
  >
    <PillWrapper keepColor={item.pillAppearance}>
      <Pill
        text={item.badgeText}
        appearance={item.pillAppearance as PillKeys}
      />
    </PillWrapper>
    <Heading inverse={!item.isDarkText} level={4} weight={3}>
      {item.heading}
    </Heading>
    <ParagraphWrapper>
      <Paragraph inverse={!item.isDarkText}>{item.description}</Paragraph>
    </ParagraphWrapper>
    <LinkWithIcon
      htmlAttributes={{
        target: "_blank"
      }}
      appearance="alt1"
      inverse={!item.isDarkText}
      text={item.linkText}
      icon={{
        name: item.iconName || "pop-out",
        justify: "right"
      }}
      href={item.link}
      onClick={() => {
        dashboardLinksClick({
          page_locale: formattedLocale,
          journey_type: item.badgeText,
          event_label: item.linkText
        });
      }}
    />
  </Container>
);
