import React from "react";

import { Modal } from "@source-web/modal";

import {
  ContentfulSecurityScoreAvailability,
  ContentfulSecurityScoreConfirmation
} from "../../../queries/queries.types";
import { SecurityScoreIntroduction } from "./SecurityScoreIntroduction";

interface SecurityScoreModalProps {
  isCyberScoreAvailable: boolean;
  isOpen: boolean;
  handleGetCyberScoreClick: any;
  onClose: () => void;
  isScoreFetched: boolean;
  confirmationContentfulFields: ContentfulSecurityScoreConfirmation["contentfulSecurityScoreConfirmation"];
  availabilityFields: ContentfulSecurityScoreAvailability["contentfulSecurityScoreAvailability"];
  formattedLocale: string;
}

export function SecurityScoreModal({
  isCyberScoreAvailable,
  handleGetCyberScoreClick,
  isOpen,
  onClose,
  availabilityFields,
  confirmationContentfulFields,
  formattedLocale
}: SecurityScoreModalProps) {
  return (
    <Modal
      overlayBlur
      srName="cyber-score-info"
      appearance="primary"
      isOpen={isOpen}
      size={3}
      animate
      isClosable
      onCloseCb={onClose}
    >
      <SecurityScoreIntroduction
        confirmationContentfulFields={confirmationContentfulFields}
        fields={availabilityFields}
        isCyberScoreAvailable={isCyberScoreAvailable}
        handleGetCyberScoreClick={handleGetCyberScoreClick}
        formattedLocale={formattedLocale}
      />
    </Modal>
  );
}
