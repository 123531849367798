import React from "react";

import { DashboardWidgetProps } from "../DashboardWidget";
import { ServiceMapperProps } from "../components/MyServices";
import { CybsafeDashboardWidget } from "./Cybsafe";
import { LookoutDashboardWidget } from "./Lookout";
import { VSDMDashboardWidget } from "./VSDM";

export function ProductWidget({
  selectedProductHighLevelId,
  cybSafeTileFields,
  lookoutFields,
  VSDMTileFields,
  setShouldShowWarningIcon,
  heading
}: {
  heading: string;
  cybSafeTileFields: DashboardWidgetProps["cybSafeTileFields"];
  lookoutFields: DashboardWidgetProps["lookoutFields"];
  VSDMTileFields: DashboardWidgetProps["VSDMTileFields"];
  selectedProductHighLevelId: number;
  setShouldShowWarningIcon: ServiceMapperProps["setShouldShowWarningIcon"];
}) {
  const productContent: Record<number, React.ReactElement> = {
    8: (
      <LookoutDashboardWidget
        heading={heading}
        setShouldShowWarningIcon={setShouldShowWarningIcon}
        lookoutFields={lookoutFields}
      />
    ),
    11: <VSDMDashboardWidget fields={VSDMTileFields} />,
    20: <CybsafeDashboardWidget cybSafeTileFields={cybSafeTileFields} />
  };

  if (
    Object.prototype.hasOwnProperty.call(
      productContent,
      selectedProductHighLevelId
    )
  )
    return productContent[selectedProductHighLevelId];

  return null;
}
