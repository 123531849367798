import { navigate } from "gatsby";
import React, { useLayoutEffect, useState } from "react";
import { useTheme } from "styled-components";

import { CardCarousel } from "@source-web/card-carousel";
import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";
import { Image } from "@source-web/image";
import { Paragraph } from "@source-web/paragraph";
import { Spacing } from "@source-web/spacing";

import { Loader } from "../../../../../components";
import { PrimaryButton } from "../../../../../components/PrimaryButton";
import { WithLightMode } from "../../../../../components/WithLightMode";
import { dashboardCTAsButtonClick, getIconForTheme } from "../../../../../lib";
import { ContentfulDashboardWidgetStepUnit } from "../../../queries/queries.types";
import { DashboardWidgetProps } from "../DashboardWidget";
import { DashboardWidgetHeader } from "../atoms";
import * as DashboardWidget from "../styles/DashboardWidget.styles";

interface CybershopOverviewProps {
  fields?: ContentfulDashboardWidgetStepUnit;
  formattedLocale: string;
  isDark: boolean;
  setFinishedWidget: DashboardWidgetProps["setFinishedWidget"];
  availableSubscriptionIds: Array<string>;
}
type CarouselType = ContentfulDashboardWidgetStepUnit["explainerTilesCarousel"];

export const CybershopOverview = ({
  fields,
  isDark,
  formattedLocale,
  setFinishedWidget,
  availableSubscriptionIds
}: CybershopOverviewProps) => {
  const theme = useTheme();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [availableExplainerTilesCarousel, setAvailableExplainerTilesCarousel] =
    useState<CarouselType>([]);

  useLayoutEffect(() => {
    const filterData = () => {
      const filteredData: CarouselType = fields?.explainerTilesCarousel?.filter(
        (item) => !availableSubscriptionIds.includes(item.productId)
      );
      setAvailableExplainerTilesCarousel(filteredData);
      // Was added as a fix for CYB-18386
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    };
    filterData();
  }, [availableSubscriptionIds, fields]);

  if (!fields) return null;

  const {
    heading,
    subHeading,
    description: { description },
    buttonText,
    skipButtonText
  } = fields;

  return (
    <React.Fragment>
      <DashboardWidgetHeader
        activeStep={3}
        heading={heading}
        subHeading={subHeading}
        skipButtonText={skipButtonText}
        handleSkipButton={() => {
          setFinishedWidget?.();
        }}
      />

      <Divider appearance="secondary" />
      <DashboardWidget.StepBody>
        <Spacing
          spacingLevel={{
            top: 4,
            bottom: 2,
            right: 8
          }}
        >
          <Paragraph justify="left">{description}</Paragraph>
        </Spacing>
        {loaded ? (
          <WithLightMode>
            <Spacing
              spacingLevel={{
                top: 3,
                bottom: 2
              }}
            >
              <DashboardWidget.RemovePager>
                <CardCarousel
                  slidesToShow={{
                    xl: 1,
                    sm: 1
                  }}
                  slidesToScroll={{
                    xl: 1
                  }}
                  dataSelectorPrefix="dashboard-carousel"
                >
                  {availableExplainerTilesCarousel?.map(
                    ({
                      dashboardWidgetCybershopDescription,
                      dashboardWidgetCybershopHeading,
                      dashboardWidgetTitle,
                      mainIcon,
                      mainIconLight
                    }) => {
                      return (
                        <DashboardWidget.CarouselItemWrapper
                          key={dashboardWidgetTitle}
                        >
                          <DashboardWidget.ImageAndTextWrapper>
                            <Image
                              dataSelectorPrefix="carousel-image"
                              alt={`${isDark ? "dark" : "light"}`}
                              sm={{
                                src: getIconForTheme(
                                  isDark,
                                  mainIcon,
                                  mainIconLight
                                )
                              }}
                              md={{
                                src: getIconForTheme(
                                  isDark,
                                  mainIcon,
                                  mainIconLight
                                )
                              }}
                              lg={{
                                src: getIconForTheme(
                                  isDark,
                                  mainIcon,
                                  mainIconLight
                                )
                              }}
                            />
                            <Spacing
                              spacingLevel={{
                                left: 5
                              }}
                            >
                              <Heading inverse={isDark} level={4} weight={3}>
                                {dashboardWidgetCybershopHeading}
                              </Heading>
                            </Spacing>
                          </DashboardWidget.ImageAndTextWrapper>
                          <Spacing
                            spacingLevel={{
                              top: 5,
                              right: 1
                            }}
                          >
                            <Heading level={5} weight={3} inverse={isDark}>
                              {dashboardWidgetTitle}
                            </Heading>
                            <Paragraph justify="left" inverse={isDark}>
                              {dashboardWidgetCybershopDescription}
                            </Paragraph>
                          </Spacing>
                        </DashboardWidget.CarouselItemWrapper>
                      );
                    }
                  )}
                </CardCarousel>
              </DashboardWidget.RemovePager>
            </Spacing>
          </WithLightMode>
        ) : (
          <Loader text={{ text: "" }} height="auto" isSmall />
        )}
      </DashboardWidget.StepBody>
      <Divider appearance="secondary" />

      <DashboardWidget.ProductRedirectButtonContainer>
        <PrimaryButton
          appearance="secondary"
          inverse={theme.name !== "WS10"}
          text={buttonText}
          onClick={() => {
            navigate(`/${formattedLocale}/cybershop`);
            dashboardCTAsButtonClick({
              page_locale: formattedLocale,
              journey_type: "Dashboard Widget Step 3",
              event_label: buttonText
            });
          }}
        />
      </DashboardWidget.ProductRedirectButtonContainer>
    </React.Fragment>
  );
};
