import styled from "styled-components";

export interface MainContainerStyledType {
  isDark?: boolean;
}

export const MainContainer = styled.main<MainContainerStyledType>`
  ${(props) => `
    background-color: ${props.isDark ? "#1e1e1e" : "#eff5f5"};
    position: relative; 
`}
`;
