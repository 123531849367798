import * as React from "react";
import { ComponentPropsWithoutRef, FC } from "react";
import styled, { css } from "styled-components";

import { formatImage } from "../../../../lib";
import { useJarvisData } from "../../queries/Jarvis.queries";
import Inner from "./assets/InnerArcs";
import Outer from "./assets/OuterArcs";

export const backgroundOffsetX = "210px";
export const backgroundOffsetY = "150px";

export interface JarvisRootProps extends ComponentPropsWithoutRef<"div"> {
  isDark?: boolean;
  background: string;
  lightBackground: string;
  tabletBackground: string;
  tabletLightBackground: string;
  mobileBackground: string;
  mobileLightBackground: string;
}

export const JarvisRoot = styled.section<JarvisRootProps>`
  ${(props) =>
    css`
      background-image: url(${props.isDark
        ? props.background
        : props.lightBackground});

      background-color: ${props.isDark ? "#1f1f1f" : "#f0f5f5"};

      & > svg {
        display: none;
      }

      @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
        padding-bottom: 150px;
        svg {
          display: block;
        }
      }

      @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
        padding-bottom: 150px;
        svg {
          display: block;
        }
      }

      @media (min-width: 769px) and (max-width: ${props.theme.breakpoints
          .lg}px) {
        position: relative;
        background-position-y: calc(50%);

        & > div {
          left: -${backgroundOffsetX};
          top: 90px;

          & > div {
            top: 135px;
          }
        }
        svg {
          display: block;
        }
      }

      @media (min-width: 769px) and (max-width: ${props.theme.breakpoints
          .lg}px) {
        svg {
          display: block;
        }
      }

      @media (max-width: ${props.theme.breakpoints.md}px) {
        display: block;
        background-image: url(${props.isDark
          ? props.mobileBackground
          : props.mobileLightBackground});
        background-size: auto 1852px;
        padding-top: 100px;
        background-position-x: calc(50%);
        background-position-y: calc(61%);

        background-color: ${props.isDark ? "#201f20" : "#f0f5f5"};
      }

      @media (width: 768px) {
        padding-top: 250px;
        background-position-y: calc(49%);
        & > svg {
          display: none;
        }
      }
    `}

  overflow: hidden;
  background-position-x: center;
  background-position-y: top;
  background-position-x: calc(50% - ${backgroundOffsetX});
  background-position-y: calc(92%);
  background-size: auto 1110px;
  background-repeat: no-repeat;
  min-height: 900px;

  display: flex;
  align-items: center;
  width: 100%;

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(-180deg);
    }
  }
  @keyframes rotate_anti {
    0% {
      transform: rotateZ(-180deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }
`;

export interface JarvisProps extends ComponentPropsWithoutRef<"div"> {
  isDark?: boolean;
}

const Jarvis: FC<JarvisProps> = ({ isDark, children }: JarvisProps) => {
  const {
    contentfulSecurityScore: {
      darkBackground,
      lightBackground,
      tabletDarkBackground,
      tabletLightBackground,
      mobileDarkBackground,
      mobileLightBackground
    }
  } = useJarvisData();

  return (
    <JarvisRoot
      isDark={isDark}
      background={formatImage(darkBackground, "2500")}
      lightBackground={formatImage(lightBackground, "2500")}
      tabletBackground={formatImage(tabletDarkBackground, "1024")}
      tabletLightBackground={formatImage(tabletLightBackground, "1024")}
      mobileBackground={formatImage(mobileDarkBackground, "767")}
      mobileLightBackground={formatImage(mobileLightBackground, "767")}
    >
      <>
        <Inner isDark={isDark} />
        <Outer isDark={isDark} />
        {children}
      </>
    </JarvisRoot>
  );
};

export default Jarvis;
