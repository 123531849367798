import { graphql, useStaticQuery } from "gatsby";

import { getJarvisType } from "./queries.types";

export const useJarvisData = () => {
  return useStaticQuery<getJarvisType>(graphql`
    query GetJarvisData($locale: String) {
      contentfulSecurityScore(node_locale: { eq: $locale }) {
        darkBackground {
          gatsbyImageData(quality: 100, width: 2500)
        }
        lightBackground {
          gatsbyImageData(quality: 100, width: 2500)
        }
        mobileDarkBackground {
          gatsbyImageData(width: 767, quality: 99)
        }
        mobileLightBackground {
          gatsbyImageData(width: 767, quality: 99)
        }
        tabletDarkBackground {
          gatsbyImageData(width: 1024, quality: 99)
        }
        tabletLightBackground {
          gatsbyImageData(width: 1024, quality: 99)
        }
      }
    }
  `);
};
