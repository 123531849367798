import styled, { css } from "styled-components";

export const PartnerDesktopHolder = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 3em auto;
  padding: 0 2.25em;
`;

export const DashboardButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  & > button {
    margin: 2em 0;
  }
`;

export const DashboardContainer = styled.div`
  ${(props) => css`
    & div[data-selector="partner-carousel"] ul {
      display: none;
    }
    & div[data-selector="partner-carousel"] button {
      border: none;
      color: white;
      background: transparent;
      svg {
        height: 24px;
        width: 24px;
        @media (min-width: ${props.theme.breakpoints.xl}px) {
          height: 55px;
          width: 55px;
        }
        @media (min-width: ${props.theme.breakpoints.md}px) {
          height: 32px;
          width: 32px;
        }
      }
    }
    & div[data-selector="partner-carousel"] button:hover {
      border: none;
      color: white;
      background: transparent;
      svg {
        stroke: rgb(255, 255, 255);
        path {
          stroke: rgb(255, 255, 255);
        }
      }
    }
  `}
`;

export const DashboardPartnerHeader = styled.div`
  ${(props) => css`
    margin: 2em 0;
    @media (min-width: ${props.theme.breakpoints.xl}px) {
      margin: 4em 0;
    }
    text-align: center;
  `}
`;

export const DescriptionWrapper = styled.div`
  ${(props) => css`
    @media (max-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      h4 {
        font-size: 20px;
      }
      p {
        text-align: center;
      }
    }
    @media (max-width: ${props.theme.breakpoints.xl}px) {
      h4 {
        font-size: 24px;
      }
    }
  `}
`;

export const CarouselItemWrapper = styled.div`
  padding-inline: 2em;
`;
