import React from "react";

import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";

import { DashboardWidgetProps } from "../DashboardWidget";
import * as DashboardWidgetStyles from "../styles/DashboardWidget.styles";

export function DashboardWidgetHeader({
  heading,
  subHeading,
  skipButtonText,
  handleSkipButton,
  activeStep,
  icon = undefined,
  isDark = false
}: {
  heading?: string;
  icon?: string;
  activeStep: number;
  subHeading?: string;
  skipButtonText?: string;
  isDark?: boolean;
  handleSkipButton?: DashboardWidgetProps["onSkip"];
}) {
  return (
    <DashboardWidgetStyles.Header haveIcon={Boolean(icon)}>
      {icon ? (
        <Icon name={icon} group={isDark ? "hifiDark" : "hifiLight"} />
      ) : null}
      <div>
        <DashboardWidgetStyles.HeadingContainer>
          <Heading text={heading} level={4} weight={2} />
          {skipButtonText && (
            <DashboardWidgetStyles.SkipButtonContainer
              onClick={() => handleSkipButton?.(activeStep + 1)}
            >
              <>
                <Heading text={skipButtonText} level={5} />
                <Icon name="chevron-right" size={3} appearance="primary" />
              </>
            </DashboardWidgetStyles.SkipButtonContainer>
          )}
        </DashboardWidgetStyles.HeadingContainer>
        <Heading text={subHeading} level={4} />
      </div>
    </DashboardWidgetStyles.Header>
  );
}
