import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

interface PlayerWrapperType {
  isModalPlayer?: boolean;
  theme: Theme;
}
export const PlayerWrapper = styled.div<PlayerWrapperType>`
  position: relative;
  ${(props) => css`
    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      padding-top: 50%;
      margin-top: ${props.isModalPlayer ? props.theme.spacing.fixed[4] : 0}px;
      margin-bottom: ${props.isModalPlayer
        ? props.theme.spacing.fixed[3]
        : 0}px;
    }
    @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
      padding-top: ${props.isModalPlayer ? 30 : 50}%;
      margin-top: 40px;
    }
    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      padding-top: ${props.isModalPlayer ? 30 : 50}%;
      margin-top: 40px;
    }
  `};
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const MainContainer = styled.main`
  height: 100%;
  min-height: 100vh;
  div[class*="DashboardContainersstyles__CardBuilderWrappe"] {
    justify-content: flex-start;
  }
`;

export const ModalText = styled.div`
  ${(props: { theme: Theme; hasSpacing?: boolean }) => css`
    ${props.hasSpacing ? `margin-top:${props.theme.spacing.fixed[2]}` : null};

    @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
      margin-bottom: ${props.theme.spacing.fixed[3]};
    }
    @media only screen and (min-width: ${props.theme.breakpoints.lg + 143}px) {
      margin-bottom: 20px;
    }
  `};
`;

export const SupportPageWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.name === "WS10"
      ? theme.color.monochrome2.default
      : theme.color.monochrome2.default};
    border-radius: ${theme.border.radius[1]};

    & > div:nth-of-type(-n + 2) {
      padding-top: ${theme.spacing.fixed[4]}px;
      padding-bottom: ${theme.spacing.fixed[4]}px;
      max-width: ${theme.gridConfig.springWidth}px;
      margin: auto;
    }

    & > div:nth-of-type(2) {
      @media (max-width: ${theme.breakpoints.lg}px) {
        margin-left: ${theme.spacing.fixed[4]}px;
        margin-right: ${theme.spacing.fixed[4]}px;
      }
    }
  `
);

export const ParagraphWrapper = styled.div(
  (props: { theme: Theme }) => css`
    display: inline;
    text-align: left;
    width: 100%;

    margin-top: ${props.theme.spacing.fixed[2]}px;

    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      max-width: ${props.theme.gridConfig.springWidth}px;
      margin-bottom: 20px;
    }

    & ul {
      padding-inline-start: 20px;
    }
  `
);

export const FormWrapper = styled.div`
  padding: 1.25em;
  max-width: ${({ theme }) => theme.gridConfig.springWidth}px;
  margin: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 1.25em;
  justify-content: center;
`;

export const ContentWrapper = styled.div(
  (props) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    @media (min-width: ${props.theme.breakpoints.sm}px) {
      padding: 1.25em;
    }
  `
);

export const PagePlayerWrapper = styled.div(
  ({ theme }: { theme: Theme }) => css`
    margin: auto;
    max-width: ${theme.gridConfig.springWidth}px;
    @media (max-width: ${theme.breakpoints.xl}px) {
      padding: 1.25em;
    }
  `
);

export const CardWrapper = styled.div(
  ({ theme }: { theme: Theme }) => css`
    max-width: ${theme.gridConfig.springWidth}px;
    margin: auto;
    padding: ${theme.spacing.fixed[8]}px 0;
    background-color: ${theme.color.monochrome2.default};
    width: 100%;
    display: flex;
    flex-direction: column;
    div[data-component-name="Container"] {
    }
    @media (min-width: ${theme.breakpoints.lg}px) {
      div[data-component-name="Container"] {
        max-width: 580px;
      }
    }
  `
);

export const SupportFormTabWrapper = styled.div(
  ({ theme }: { theme: Theme }) => css`
    & > div[data-component-name="Tabs"] {
      > div:nth-child(1) {
        background: ${theme.color.monochrome1.default};
      }
      & > div > div[role="tablist"] {
        max-width: 1440px;
        margin: auto;
        justify-content: flex-start;
      }
    }
  `
);

export const HeadingWrapper = styled.div(
  ({ theme }: { theme: Theme }) => css`
    @media (max-width: ${theme.breakpoints.lg}px) {
      padding: ${theme.spacing.fixed[4]}px;
    }
  `
);
