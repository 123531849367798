import { DefaultTheme } from "styled-components";

import { ExploreTileThemeType } from "./ExploreTile.theme.types";

const exploreTileLocal = (theme: DefaultTheme): ExploreTileThemeType => {
  return {
    background: `linear-gradient(47deg, rgba(255,255,255,0.79) 0%, rgba(247,247,247,0.70) 100%);`,
    borderColor: theme.color.monochrome2.default,
    imageBackground: theme.color.monochrome2.default
  };
};

export default exploreTileLocal;
