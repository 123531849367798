import { navigate } from "gatsby";
import { paramCase } from "param-case";
import React from "react";
import { useTheme } from "styled-components";

import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";
import { Spacing } from "@source-web/spacing";

import { PartnerImage } from "../../../../../components/PartnerImage";
import { PrimaryButton } from "../../../../../components/PrimaryButton";
import { dashboardCTAsButtonClick } from "../../../../../lib";
import {
  ContentfulDashboardWidgetStepUnit,
  ContentfulServiceTileWidget
} from "../../../queries/queries.types";
import { DashboardWidgetProps } from "../DashboardWidget";
import { DashboardWidgetHeader } from "../atoms";
import * as DashboardWidget from "../styles/DashboardWidget.styles";

interface ServiceOverviewProps {
  handleSkipTour: DashboardWidgetProps["onSkip"];
  fields: ContentfulDashboardWidgetStepUnit | undefined;
  serviceTiles: ContentfulServiceTileWidget | undefined;
  formattedLocale: string;
  isDark: boolean;
}

export const ServicesOverview = ({
  handleSkipTour,
  fields,
  serviceTiles,
  formattedLocale,
  isDark
}: ServiceOverviewProps) => {
  const theme = useTheme();

  const node = fields?.step === 2 ? fields : undefined;

  const localeServiceId = formattedLocale === "pt" ? 11 : 8;

  const targetNode = serviceTiles?.allContentfulServiceTile.nodes.find(
    (node) =>
      node.serviceHlId === localeServiceId && node.dashboardWidgetText !== null
  );

  if (!targetNode || !node) return null;

  const {
    heading,
    subHeading,
    description: { description },
    buttonText,
    skipButtonText
  } = node;

  const handleNavigate = () =>
    navigate(
      `/${formattedLocale}/my-services?service=${paramCase(
        targetNode.customRouteName || targetNode.heading
      )}`
    );

  const tile = fields?.explainerTilesCarousel?.find(
    (tile) => tile.serviceId === localeServiceId
  );

  return (
    <React.Fragment>
      <DashboardWidgetHeader
        activeStep={2}
        heading={heading}
        subHeading={subHeading}
        skipButtonText={skipButtonText}
        handleSkipButton={handleSkipTour}
      />
      <Divider appearance="secondary" />
      <DashboardWidget.StepBody>
        <Spacing
          spacingLevel={{
            top: 4,
            bottom: 4,
            right: 10
          }}
        >
          <Paragraph justify="left">{description}</Paragraph>
        </Spacing>

        {tile && (
          <Spacing
            spacingLevel={{
              top: 4,
              bottom: 4,
              left: 8,
              right: 8
            }}
          >
            <PartnerImage
              isDark={isDark}
              heading={tile.dashboardWidgetCybershopHeading}
              mainIcon={tile.mainIcon}
              mainIconLight={tile.mainIconLight}
              alt={
                tile.dashboardWidgetCybershopHeading || "vodafone-partner-image"
              }
            />
            <Heading text={targetNode?.heading} level={4} weight={3} />

            <Paragraph justify="left">
              {targetNode?.dashboardWidgetText}
            </Paragraph>
          </Spacing>
        )}
      </DashboardWidget.StepBody>
      <Divider appearance="secondary" />

      <DashboardWidget.ProductRedirectButtonContainer>
        <PrimaryButton
          appearance="secondary"
          inverse={theme.name !== "WS10"}
          text={buttonText}
          onClick={() => {
            handleNavigate();
            dashboardCTAsButtonClick({
              page_locale: formattedLocale,
              journey_type: "Dashboard Widget Step 2",
              event_label: buttonText
            });
          }}
        />
      </DashboardWidget.ProductRedirectButtonContainer>
    </React.Fragment>
  );
};
