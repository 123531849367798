import React from "react";

import { Heading } from "@source-web/heading";
import { Spacing } from "@source-web/spacing";

import { PrimaryButton } from "../../../../../../../components/PrimaryButton";
import SecurityScore from "../../../../../../../components/SecurityScore";
import { CyberScorePopupProps } from "./CyberScorePopup.types";
import * as Styled from "./styles/CyberScorePopup.style";

const CyberScorePopup = ({
  children,
  heading,
  confirmBtnProps
}: CyberScorePopupProps) => {
  return (
    <Styled.ModalContainer>
      <Styled.DescriptionsContainer>
        <Heading {...heading} />
        {children && (
          <Spacing {...{ spacingLevel: { bottom: 7 } }}>{children}</Spacing>
        )}
        <Styled.ButtonContainer>
          <PrimaryButton {...confirmBtnProps} />
        </Styled.ButtonContainer>
      </Styled.DescriptionsContainer>
      <Styled.EmptyWidgetAsBackgroundWrapper>
        <SecurityScore
          hideScore
          isCalculating={true}
          score={0}
          rating={""}
          marginDesktop={1}
        />
      </Styled.EmptyWidgetAsBackgroundWrapper>
    </Styled.ModalContainer>
  );
};

export default CyberScorePopup;
