import styled, { css } from "styled-components";

import { elevation } from "@source-web/mixins";

import localTheme from "../themes/ExploreTile.theme";
import { StyledExploreTileProps } from "./ExploreTile.style.types";

export const ExploreTileWrapper = styled.a<StyledExploreTileProps>(
  (props: StyledExploreTileProps) => {
    const appearanceFromProp = localTheme(props.theme);

    return css`
      box-sizing: border-box;
      width: 400px;
      height: 156px;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      text-align: left;
      background: ${appearanceFromProp.background};
      padding: 20px;
      border-radius: ${props.theme.border.radius["2"]};
      border: 1px solid ${appearanceFromProp.borderColor};
      ${elevation(true)}
      text-decoration: none;

      ${(props) => `
        @media (max-width: ${props.theme.breakpoints.lg}px) {
          width: 358px;
          height: 156px;
          padding: 16px
        }
        
        @media (max-width: ${props.theme.breakpoints.md}px) {
          width: 100%;
          height: 156px;
          padding: 16px
        }`};
    `;
  }
);

export const ExploreTileContent = styled.div<StyledExploreTileProps>(
  (props) => css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-right: ${props.notifications ? "10px" : "25px"};
    width: 100%;
  `
);

export const IconTextWrapper = styled.div(
  () => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;

    & > span:last-child {
      padding-left: 8px;
    }
  `
);

export const ImageWrapper = styled.div<StyledExploreTileProps>(
  (props: StyledExploreTileProps) => {
    const appearanceFromProp = localTheme(props.theme);

    return css`
      align-self: center;
      background-color: ${appearanceFromProp.imageBackground};
      border-radius: ${props.theme.border.radius["2"]};
      min-width: 116px;
      max-width: 116px;
      height: 116px;
      display: flex;
      justify-content: center;
      align-items: center;
    `;
  }
);

// Need the custom background colour until theme can be sorted out
export const Heading = styled.span(
  (props) => `
  display: inline-flex;
  justify-content: flex-start;
  & > span {
    margin-top: 4px;
  }
  & > h4 {
    margin: 0 10px 0 0;
  }
   ${
     props.theme.name !== "WS10"
       ? `& > span {
       
    background-color: #E60000
    }`
       : ""
   }
`
);
