import { navigate } from "gatsby";
import React from "react";
import { useTheme } from "styled-components";

import { CardCarousel } from "@source-web/card-carousel";
import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";
import { Spacing } from "@source-web/spacing";

import { PartnerImage } from "../../../../components/PartnerImage";
import { PrimaryButton } from "../../../../components/PrimaryButton";
import { dashboardCTAsButtonClick } from "../../../../lib";
import { ContentfulCarousel } from "../../queries/queries.types";
import * as Styled from "./styles/DashboardPartner.styles";

export interface DashboardPartnerProps {
  data: ContentfulCarousel["contentfulCarousel"];
  isDark: boolean;
}

const DashboardPartner = ({ data, isDark }: DashboardPartnerProps) => {
  const { header, description, carousel, buttonText, buttonLink, node_locale } =
    data;
  const theme = useTheme();
  const formattedLocale = node_locale.split("-")[0];
  const navigateTo = (to: string) => navigate(`/${formattedLocale}/${to}`);
  const DashboardPartnerHeader = (
    <Styled.DashboardPartnerHeader>
      <Heading
        appearance="primary"
        inverse={theme.name !== "WS10"}
        level={1}
        size={3}
        weight={3}
        text={header}
        noMargin
      />
      <Heading
        appearance="primary"
        inverse={theme.name !== "WS10"}
        level={1}
        size={2}
        weight={1}
        text={description}
        noMargin
      />
    </Styled.DashboardPartnerHeader>
  );
  const filteredCarouselData =
    formattedLocale === "it"
      ? carousel.filter((item) => item.headingText.toLowerCase() !== "cybsafe")
      : carousel;
  const DashboardPartnerSection = filteredCarouselData.map(
    ({
      dashboardWidgetCybershopHeading,
      partnerWidgetTitle,
      partnerWidgetDescription,
      mainIcon,
      mainIconLight
    }) => (
      <Styled.CarouselItemWrapper key={partnerWidgetTitle}>
        <PartnerImage
          isDark={isDark}
          heading={dashboardWidgetCybershopHeading}
          mainIcon={mainIcon}
          mainIconLight={mainIconLight}
          alt="partner"
        />
        <Spacing
          spacingLevel={{
            top: 3,
            right: 1
          }}
        >
          <Styled.DescriptionWrapper>
            <Heading level={4} weight={3}>
              {partnerWidgetTitle}
            </Heading>
            <Paragraph justify="left" inverse={isDark} weight={2}>
              {partnerWidgetDescription}
            </Paragraph>
          </Styled.DescriptionWrapper>
        </Spacing>
      </Styled.CarouselItemWrapper>
    )
  );

  return (
    <Styled.PartnerDesktopHolder>
      {DashboardPartnerHeader}
      <Styled.DashboardContainer>
        <CardCarousel
          slidesToShow={{
            xl: 4,
            lg: 3,
            md: 2,
            sm: 1
          }}
          dataSelectorPrefix="partner-carousel"
        >
          {DashboardPartnerSection}
        </CardCarousel>
      </Styled.DashboardContainer>
      <Styled.DashboardButton>
        <PrimaryButton
          onClick={() => {
            navigateTo(buttonLink);
            dashboardCTAsButtonClick({
              page_locale: formattedLocale,
              journey_type: header,
              event_label: buttonText
            });
          }}
          text={buttonText}
          appearance="primary"
          inverse={theme.name !== "WS10"}
        />
      </Styled.DashboardButton>
    </Styled.PartnerDesktopHolder>
  );
};

export default DashboardPartner;
