import React from "react";

import { Card } from "../../../../components/Card";
import {
  AllContentfulDashboardContainers,
  ContentfulDashboardContainers
} from "../../queries/queries.types";
import {
  CardBuilderWrapper,
  LayoutContainer
} from "./styles/DashboardContainers.styles";

interface IDashboardContainers {
  data?: AllContentfulDashboardContainers;
  formattedLocale: string;
}

export const DashboardContainers = ({
  data,
  formattedLocale
}: IDashboardContainers) => (
  <LayoutContainer>
    <CardBuilderWrapper>
      {data &&
        data.edges.map((item: ContentfulDashboardContainers, index) => (
          <Card
            item={item.node}
            key={index}
            formattedLocale={formattedLocale}
          />
        ))}
    </CardBuilderWrapper>
  </LayoutContainer>
);
